import React, { useEffect } from "react";
// import { getToken } from "../../../api/Token";
// import { Tokenscolumns } from "../../../Components/GridHeaders";
import Grid from "../../Components/Grid";
import { Link } from "react-router-dom";
import { getSSPsAPI } from "../../API/SSP";
import { decrypytdata } from "../../API/walletkey";
import { Limitscolumns } from "../../Components/GridHeaders";
import Footer from "../../Views/admin/SysAdmin/Footer";
import Header from "../../Views/admin/SysAdmin/Header";
// import { showLoader } from "../../../action";

function SettlementNetworksBalance() {
  const [rows, setRows] = React.useState([]);
  console.log(
    "🚀 ~ file: GetSSP.js ~ line 12 ~ SettlementNetworksBalance ~ rows",
    rows
  );
  const userData = JSON.parse(localStorage?.getItem("UserLogIn"));
  //   const dispatch = useDispatch()

  const initialValues = {
    sspTypecode: "",
    sspCode: "",
  };
  //   const initialValues = {
  //     sspTypecode: "GRANTOR",
  //     sspCode: "UMU",
  //   };

  let APPID = "";
  let APIKEY = "";

  const fetchdata = async () => {
    if (userData.PublicAddress) {
      APPID = userData.PublicAddress;
    }
    if (userData.PrivateKey) {
      APIKEY = userData.PrivateKey;
    }
    if (APPID && APIKEY) {
      console.log(
        "🚀 ~ file: GetSSP.js ~ line 27 ~ fetchdata ~ APIKEY",
        APIKEY
      );
      console.log("🚀 ~ file: GetSSP.js ~ line 27 ~ fetchdata ~ APPID", APPID);
      //   dispatch(showLoader(true));
      const encryptedLedger = await getSSPsAPI(APPID, APIKEY, initialValues);
      //   dispatch(showLoader(false));
      const tokens = await decrypytdata(encryptedLedger.data, APIKEY);
      console.log(
        "🚀 ~ file: TokensBalances.js ~ line 30 ~ fetchdata ~ tokens",
        tokens
      );
      // const data = JSON.parse(tokens);
      let data = JSON.parse(JSON.parse(tokens).CommandResponse);
      console.log(
        "🚀 ~ file: TokensBalances.js ~ line 33 ~ fetchdata ~ data",
        data
      );

      if (data) {
        let dataArr = [];
        data?.forEach((dataMain, index) => {
          // multiple data
          dataArr.push({ ...dataMain, rowid: index });
        });
        setRows(dataArr);
      }
    }
  };

  useEffect(() => {
    fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Header />
      <div className="col-md-9 col-lg-9 col-xxl-10">
        <div className="row ms-lg-4 ms-xl-5 m-0">
          <div className="col-12">
            <div className="main-heading">
              <h3> Settlement-Networks</h3>
              <h6>Get settlement-Networks</h6>
              <p>
                This function lists all the settlement-networks associated with
                a wallet.
              </p>
            </div>
            <div className="row">
              <div className="col-xxl-10">
                <div className="wallet-balances-box">
                  <div className="d-md-flex justify-content-between">
                    <div className="search-box d-md-flex">
                      <div className="input-group mb-3"></div>
                    </div>
                    <Link to={`/add-spp`}>
                      <button
                        type="button"
                        className="btn btn-primary "
                        style={{ marginBottom: "10px" }}
                      >
                        + ADD SSP
                      </button>
                    </Link>
                  </div>
                  <div style={{ height: 800, width: "100%" }}>
                    <Grid row={rows} coloums={Limitscolumns} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SettlementNetworksBalance;
