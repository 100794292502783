import { WEB_WALLET_URL } from "../Constants/Config";
import http from "./httpService";
import { hmacencrypt } from "./walletkey";

export async function getSSPsAPI(publickey, privateKey, initialValues) {
  let values = {
    sourcePublicAddress: publickey,
  };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/ssp/GetSSPs",
    values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/ssp/GetSSPs",
    // { sourcePublicAddress: publickey,tokenSymbol: "UMU" },
    values,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}
