import React from "react";
const RegisterPage = () => {
    return (
        <>
            <div className="transfer-rates-page ps-5">
                <div className="transfer-rates ps-4">
                    <div className="row transfer-rates-page">
                        <div className="col-xxl-11 ">
                            <div className="main-head">
                                <i className="bi bi-arrow-left"></i>
                                <h2>User Registration</h2>
                            </div>
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                                        <div className="domestic">
                                            <img src="../icons/Single Registration.png" className="Domestic-icon-img show" alt="Domestic-icon-img" />
                                            <img src="../icons/Single Registration-active.png" className="Domestic-icon-img none" alt="Domestic-icon-img" />
                                        </div>
                                        <h3>Single Registration</h3>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                        <div className="domestic">
                                            <img src="../icons/batch regist.png" className="International-icon-img show" alt="International-icon-img" />
                                            <img src="../icons/batch regist-active.png" className="International-icon-img none" alt="International-icon-img" />
                                        </div>
                                        <h3>Batch Registration</h3>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="format-tab" data-bs-toggle="tab" data-bs-target="#format" type="button" role="tab" aria-controls="format" aria-selected="false">
                                        <div className="domestic">
                                            <img src="../icons/Batch File Format.png" className="International-icon-img show" alt="International-icon-img" />
                                            <img src="../icons/Batch File Format-active.png" className="International-icon-img none" alt="International-icon-img" />
                                        </div>
                                        <h3>Batch File Format</h3>
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div className="mt-4">
                                        <div className="single-registration">
                                            <h3>Corporate Information</h3>
                                            <div className="row">
                                                <div className="col-md-6 col-xl-3 mb-4 mb-xl-5">
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        placeholder="Group Class"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-xl-3 mb-4 mb-xl-5">
                                                    <select className="form-select form-control">
                                                        <option defaultValue>Group Type</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-6 col-xl-3 mb-4 mb-xl-5">
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        placeholder="Corporate Name"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-xl-3 mb-4 mb-xl-5">
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        placeholder="Alias Name"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-xl-3 mb-4 mb-xl-5">
                                                    <select className="form-select form-control">
                                                        <option defaultValue>Country</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-6 col-xl-3 mb-4 mb-xl-5">
                                                    <select className="form-select form-control">
                                                        <option defaultValue>State</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-6 col-xl-3 mb-4 mb-xl-5">
                                                    <select className="form-select form-control">
                                                        <option defaultValue>City</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-6 col-xl-3 mb-4 mb-xl-5">
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        placeholder="Postal Code"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-xl-3 mb-4 mb-xl-5">
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        placeholder="Street Address Line 1"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-xl-3 mb-4 mb-xl-5">
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        placeholder="Street Address Line 2"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-xl-3 mb-4 mb-xl-5">
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        placeholder="Street Address Line 3"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="single-registration">
                                            <h3>Contact Information</h3>
                                            <div className="row">
                                                <div className="col-md-6 col-xl-3 mb-4 mb-xl-5">
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        placeholder="First Name"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-xl-3 mb-4 mb-xl-5">
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        placeholder="Middle Name"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-xl-3 mb-4 mb-xl-5">
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        placeholder="Last Name"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-xl-3 mb-4 mb-xl-5">
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        placeholder="Second Last Name (if applicable)"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-xl-3 mb-4 mb-xl-5">
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        placeholder="Email Address"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-xl-3 mb-4 mb-xl-5">
                                                    <select className="form-select form-control">
                                                        <option defaultValue>Dialing Country Code</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-6 col-xl-3 mb-4 mb-xl-5">
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        placeholder="Corporate Telephone"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-xl-3 mb-4 mb-xl-5">
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        placeholder="Alias Name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end mb-5">
                                            <button type="button" className="btn cancel-btn mb-3 mb-md-0 me-4" > CANCEL </button>
                                            <button type="button" className="btn next-btn mb-3 mb-md-0" > SUBMIT </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <div className="mt-4">
                                        <div className="single-registration batch-registration">
                                            <p>Upload a User Registration File in the proper file format for batched processing</p>

                                            <div className="row m-0">
                                                <div className="col-md-6 col-xl-4 mb-4 mb-xl-0">
                                                    <div className="mb-5">
                                                        <label>Upload User Registration</label>
                                                        <input className="form-control-file w-100" type="file" />
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-end mb-5">
                                                    <button type="button" className="btn cancel-btn mb-3 mb-md-0 me-4" > CANCEL </button>
                                                    <button type="button" className="btn next-btn mb-3 mb-md-0" > SUBMIT </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="format" role="tabpanel" aria-labelledby="format-tab">
                                    <div className="mt-4">
                                        <div className="single-registration batch-registration batch-file-format">
                                            <p>The file format for batched user registration is a comma separate file (CSV) with the following sequential field names:</p>
                                            <div className="row m-0">
                                                <div className="col-12 mb-5">
                                                    <div className="table-responsive">
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>Field Name</th>
                                                                    <th>Field Description</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>First Name</td>
                                                                    <td>The first name of the user</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Middle Name</td>
                                                                    <td>The middle name of the user</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Email</td>
                                                                    <td>The email address</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Password</td>
                                                                    <td>The login password</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RegisterPage;
