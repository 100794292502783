import React from "react";
import { Link } from "react-router-dom";
//import { Link } from "react-router-dom";
function PaymentSystems() {
  return (
    <>
      <div className="central-bank-digital-currencies py-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 mb-5">
              <h3>Payment Systems</h3>
            </div>
            <div className=" col payment-systems-box mb-5 mb-xl-0">
            <Link to="/admin/clearing-system-type">
              <div className="">
                <div className="box-icon">
                  <img
                    src="../icons/clearing-type.png"
                    className="icons-two"
                    alt="icon"
                  />
                </div>
                <h5>Clearing Type</h5>
              </div>
              </Link>
            </div>
            <div className="col payment-systems-box mb-5 mb-xl-0">
            <Link to="/admin/clearing-system">
              <div className="">
                <div className="box-icon">
                  <img
                    src="../icons/clearing-systems.png"
                    className="icons-two"
                    alt="icon"
                  />
                </div>
                <h5>Clearing Systems</h5>
              </div>
              </Link>
            </div>
            <div className=" col payment-systems-box mb-5 mb-xl-0">
            <Link to="/admin/routing-system-type">
              <div className="">
                <div className="box-icon">
                  <img
                    src="../icons/routing-type.png"
                    className="icons-two"
                    alt="icon"
                  />
                </div>
                <h5>Routing Type</h5>
              </div>
              </Link>
            </div>
            <div className="col payment-systems-box mb-5 mb-sm-0 mb-md-5 mb-xl-0">
            <Link to="/admin/routing-systems">
              <div className="">
                <div className="box-icon">
                  <img
                    src="../icons/routing-systems.png"
                    className="icons-two"
                    alt="icon"
                  />
                </div>
                <h5>Routing Systems</h5>
              </div>
              </Link>
            </div>
            <div className="col payment-systems-box mb-md-5 mb-xl-0">
            <Link to="/admin/swift-codes">
              <div className="">
                <div className="box-icon">
                  <img
                    src="../icons/swift-codes.png"
                    className="icons-two"
                    alt="icon"
                  />
                </div>
                <h5>SWIFT CODES</h5>
              </div>
              </Link>
            </div>
            <div className=" col payment-systems-box">
              <Link to="/admin/settlement-networks">
                <div className="">
                  <div className="box-icon">
                    <img
                      src="../icons/payment-networks.png"
                      className="icons-two"
                      alt="icon"
                    />
                  </div>
                  <h5>SSPs</h5>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentSystems;
