import React from 'react';
function Footer() {
    return (
        <>
            <div className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-md-end">
                            <div className="footer-text">
                                {/*<h5>Unicoin<span>TM</span> is an innovation of Digital Currency Monetary Authority.</h5>*/}
                                {/*<a href="/">UnicoinNetwork.com</a>*/}
                                <a href="https://dcma.io" target="_blank" rel="noreferrer"><img src="../images/dcma_logo.png" alt="" /></a>
                                <p>&copy; 2022 All Right Reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;