import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { showLoader } from "../../../../../action";
import { getDropdownvalues } from "../../../API/dropdown";
import {
  FetchLedgers,
  FetchLedgersChild,
} from "../../../API/MoneyServices";
import Pagination from "../../../Components/NewPagination";
// import NewDatagrid from "../../NewDatagrid";

function Ledger({ setsourceData, nextstep , userData}) {
  // const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  // const [ledgerTypedropdown, setLedgerTypedropdown] = useState([]);
  const [ledgerClass, setLedgerClass] = useState([]);
  // const [findLedgerType, setFindLedgerType] = useState([]);
  const [maxRows, setMaxRows] = useState("");
  const [FindLedger, setFindLedger] = useState("");
  const [findLedgerClass, setFindLedgerClass] = useState([]);
  const [selected, setSelected] = useState("RESERVED");
  const [rows, setRows] = React.useState([]);
  console.log("🚀 ~ file: Ledger.js ~ line 31 ~ Ledger ~ rows", rows);

  // const dispatch = useDispatch();

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  //paginason
  const [currentPage, setCurrentPage] = useState(1);
  const [postparPage, setPostparPage] = useState(25);
  // const [currentPage] = useState(1);
  // const [postparPage, setPostparPage] = useState(10);

  // const indexofLastpage = currentPage * postparPage;
  // const indexofFirstpost = indexofLastpage - postparPage;

  const publicKeys = useSelector((state) => state.keyReducer);
  // const [temp, setTemp] = useState([]);

  let APPID = "";
  let APIKEY = "";
  const fetchdata = async () => {
    if (userData.PublicAddress) {
      APPID = userData.PublicAddress;
    }
    if (userData.PrivateKey) {
      APIKEY = userData.PrivateKey;
    }
    if (APPID && APIKEY) {
      // dispatch(showLoader(true));
      const ndata = {
        sourcePublicAddress: APPID,
        ledgerClasscode: selected,
        itemcount: postparPage,
        pageId: currentPage,
        searchPhrase: FindLedger,
        // ledgerTypecode: findLedgerType,
      };
      // const grid = { itemcount: 10, pageId: 1 };
      const encryptedLedger = await FetchLedgers(APPID, APIKEY, ndata);
      console.log(
        "🚀 ~ file: Ledger.js ~ line 72 ~ fetchdata ~ encryptedLedger",
        encryptedLedger
      );

      // const tokens = await decrypytdata(encryptedLedger.data, APIKEY);

      // const data = JSON.parse(JSON.parse(tokens).CommandResponse);
      // const data = JSON.parse(tokens);
      let data = encryptedLedger.data;

      if (data) {
        let dataArr = [];
        let temp = data.shift();

        if (temp) {
          setMaxRows(temp.MaxRows);
        }
        data?.forEach((dataMain, index) => {
          dataArr.push({ ...dataMain, rowid: index });
        });
        setRows(dataArr);
      }
    }
    // dispatch(showLoader(false));
  };

  const fetchdataChildHandler = async (ndata) => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      //  dispatch(showLoader(true));
      const values = {
        parentID: ndata,
      };
      // if (bankclassData && bankTypeData) {
      //   values = {
      //     sourcePublicAddress: APPID,
      //     bankclasscode: bankclassData,
      //     bankTypecode: bankTypeData,
      //   };
      // } else {
      //   values = {
      //     sourcePublicAddress: APPID,
      //     // parentBankID:"",
      //   };
      // }

      const encryptedLedger = await FetchLedgersChild(APPID, APIKEY, values);
      // const tokens = await decrypytdata(encryptedLedger.data, APIKEY);
      // const data = JSON.parse(JSON.parse(tokens).CommandResponse);
      const data = encryptedLedger?.data;
      // setbankTreeDatas(encryptedLedger?.data?.bankTree);
      // let data = JSON.parse(JSON.parse(tokens).CommandResponse);

      if (data) {
        let dataArr = [];
        data?.forEach((dataMain, index) => {
          // multiple data
          dataArr.push({ ...dataMain, rowid: index });
        });
        setRows(dataArr);
      }
    }
    // dispatch(showLoader(false));
  };
  const fetchdropdwon = async () => {
    let lclass = await getDropdownvalues("LKLedgerClass");
    setLedgerClass(lclass);

    // let dropdownvalues = await getDropdownvalues("LKLedgerType");
    // setLedgerTypedropdown(dropdownvalues);
    // let dialing = await getDailingDropDown("GetDialingcodes");
    // setDailingCode(dialing);
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        // setisValidPublicAddress(true);
        fetchdropdwon();
        fetchdata();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  useEffect(() => {
    fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findLedgerClass, postparPage, currentPage, FindLedger]);

  useEffect(() => {
    setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postparPage]);

  const initialValues = {};

  const [checkedRows, setCheckedRows] = useState();

  return (
    <>
      {/* {isValidPublicAddress && ( */}
        <div>
          <div className="row mt-5">
            <div className="col-xxl-11">
              <Formik
                initialValues={initialValues}
                // validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                  console.log(
                    "🚀 ~ file: CreateIssuer.js ~ line 174 ~ CreateIssuer ~ values",
                    values
                  );
                  // debugger;
                  // CreateIssuersApi(values);
                }}
              >
                {(formik) => {
                  const { setFieldValue } = formik;
                  return (
                    <div className="wallet-balances-box">
                      <div className="d-md-flex justify-content-end px-4">
                        <div className="">
                          <div className="line-input input-group mb-3">
                            <span className="input-group-text">
                              <i className="bi bi-search"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="search"
                              onChange={(e) => {
                                setFindLedger(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className=" ">
                        <Paper
                          sx={{
                            width: "100%",
                            overflow: "hidden",
                            border: 1,
                            borderColor: "#c6c6c6",
                          }}
                        >
                          <TableContainer
                            component={Paper}
                            sx={{
                              boxShadow: "none",
                              maxHeight: 500,
                              borderBottom: 1,
                              borderColor: "#c6c6c6",
                            }}
                          >
                            <Table
                              stickyHeader
                              className="table table-hover table-striped"
                              sx={{ minWidth: 650, mb: 0 }}
                              aria-label="simple table"
                            >
                              <TableHead
                                className="sticky-top"
                                sx={{
                                  zIndex: "10",
                                  borderBottom: 1,
                                  borderColor: "#c6c6c6",
                                  backgroundColor:"#fff",
                                }}
                              >
                                <TableRow sx={{ height: "1px" }}>
                                  <TableCell sx={{ height: "inherit" }}>
                                    <Box sx={{ height: "100%" }}>
                                      <label className="table-lable">
                                        Select
                                      </label>
                                    </Box>
                                  </TableCell>
                                  <TableCell sx={{ height: "inherit" }}>
                                    <Box sx={{ height: "100%" }}>
                                      <label className="table-lable">
                                        Ledger
                                      </label>
                                    </Box>
                                  </TableCell>
                                  <TableCell sx={{ minWidth: "176px" }}>
                                    <label className="table-lable">
                                      Ledger Class
                                    </label>
                                    <Field
                                      as="select"
                                      className="form-select form-control"
                                      id="ledgerclass"
                                      name="ledgerclass"
                                      onChange={(e) => {
                                        setFieldValue(
                                          "ledgerclass",
                                          e.target.value
                                        );
                                        setSelected(e.target.value);
                                        setFindLedgerClass(e.target.value);
                                        console.log(
                                          "🚀 ~ file: CreateLedger.js ~ line 192 ~ CreateLedger ~ e.target.value",
                                          e.target.value
                                        );
                                      }}
                                      onBlur={formik.handleBlur}
                                      value={selected}
                                    >
                                      <option selected value={" "}>
                                        Ledger Class
                                      </option>
                                      {ledgerClass &&
                                        ledgerClass.map((value, index) => {
                                          return (
                                            <>
                                              <option
                                                value={value.keycode}
                                                key={index}
                                              >
                                                {value.displayName}
                                              </option>
                                            </>
                                          );
                                        })}
                                    </Field>
                                    <div style={{ color: "red" }}>
                                      <ErrorMessage
                                        name="ledgerclass"
                                        component="span"
                                        className="error"
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell sx={{ height: "inherit" }}>
                                    <Box sx={{ height: "100%" }}>
                                      <label className="table-lable">
                                        Ledger Type
                                      </label>
                                    </Box>

                                    <div style={{ color: "red" }}>
                                      <ErrorMessage
                                        name="ledgerTypeKeycode"
                                        component="span"
                                        className="error"
                                      />
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rows?.map((value, index) => {
                                  return (
                                    <>
                                      <TableRow
                                        onDoubleClick={() => {
                                          setsourceData(value);
                                          nextstep();
                                        }}
                                      >
                                        <TableCell sx={{position:"relative"}}>
                                          <div className=" table-form-check ">
                                            <input
                                              // checked={checkedRows[index]}
                                              className="form-check-input ml-0"
                                              type="checkbox"
                                              id="flexCheckDefault"
                                              checked={
                                                checkedRows === value.RowID
                                              }
                                              // label={}
                                              onChange={(e) => {
                                                console.log(
                                                  e.target.checked,
                                                  "checked"
                                                );
                                                setCheckedRows(value.RowID);
                                                if (e.target.checked === true) {
                                                  setsourceData(value);

                                                  // checked(e.target.checked, index)
                                                }
                                              }}
                                              name="flexCheckDefault"
                                            />
                                          </div>
                                        </TableCell>
                                        <TableCell
                                          onClick={() => {
                                            fetchdataChildHandler(
                                              value.LedgerTreeID
                                            );
                                          }}
                                        >
                                          {value.Displayname}
                                        </TableCell>
                                        <TableCell
                                          onClick={() => {
                                            fetchdataChildHandler(
                                              value.LedgerTreeID
                                            );
                                          }}
                                        >
                                          {value.Ledgerclass}
                                        </TableCell>
                                        <TableCell
                                          onClick={() => {
                                            fetchdataChildHandler(
                                              value.LedgerTreeID
                                            );
                                          }}
                                        >
                                          {value.LedgerType}
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <Box sx={{ width: "100%" }}>
                            <Box className="row py-3 px-2">
                              <Box className="col-12 col-md-3 col-xl-2">
                                <div className="row-select">
                                  <select
                                    class="form-select pagination-select"
                                    aria-label="Default select example"
                                    onChange={(e) => {
                                      setPostparPage(e.target.value);
                                    }}
                                    // placeholder="Bank Type"
                                  >
                                    {/* <option selected>Select rows</option> */}

                                    <option value={10}>10 Rows</option>
                                    <option selected value={25}>
                                      25 Rows
                                    </option>
                                    <option value={50}>50 Rows</option>
                                    <option value={100}>100 Rows</option>
                                    <option value={200}>200 Rows</option>
                                  </select>
                                </div>
                              </Box>

                              <Box className="col-12 col-xl-10 col-md-9 d-flex justify-content-end align-items-center">
                                <Pagination
                                  postsperPage={postparPage}
                                  maxRows={maxRows}
                                  paginate={paginate}
                                  currentPage={currentPage}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Paper>

                        {/* <Pagination
            postsperPage={postparPage}
            totalPost={rows?.length}
            paginate={paginate}
            currentPage={currentPage}
          /> */}
                      </div>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      {/* )} */}
    </>
  );
}
export default Ledger;
