import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setResponseAPI, setResult } from "../../action";
// import {
//   setPrivateId,
//   setResponseAPI,
//   setResult,
//   showLoader,
// } from "../../../../action";
import {
  CreateTrasferApi,
  DepositAPI,
  WithdrawAPI,
} from "../../API/MoneyServices";
import { decrypytdata } from "../../API/walletkey";
// import { success } from "../../../../Components/alert";
import { ADMIN_URL } from "../../Constants/Config";

// import { useEffect } from "react";
// import { useSelector } from "react-redux";
// import { FetchCallDataAPI } from "../../../../api/MoneyServices";
// import { decrypytdata } from "../../../../api/walletKey";
// import { success } from "../../../../Components/alert";
// import PrivateModal from "../../../../Components/PrivateModal";
function Money({
  previous,
  sourceData,
  targetData,
  paymentData,
  step,
}) {
  const [fetchData, setFetchData] = useState();
  console.log("🚀 ~ file: Review.js ~ line 64 ~ fetchData", fetchData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const targUnitsData = paymentData?.TargetAmount * fetchData?.FXRate;
  const MainData = targUnitsData?.toFixed(6);
  const SourceAmountData = parseFloat(MainData);
  // console.log("CalculatedAmount===== ", SourceAmountData, typeof SourceAmountData ,
  //  "paymentData" ,paymentData.TargetAmount, typeof paymentData.TargetAmount , "*" , fetchData?.FXRate);

  const initialValues = {
    sourcePublicAddress: fetchData?.SourcePublicAddress
      ? fetchData?.SourcePublicAddress
      : "",
    targetPublicAddress: fetchData?.TargetPublicAddress
      ? fetchData?.TargetPublicAddress
      : "",
    sourceTokenSymbol: fetchData?.SourceTokenSymbol
      ? fetchData?.SourceTokenSymbol
      : "",
    targetTokenSymbol: fetchData?.TargetTokenSymbol
      ? fetchData?.TargetTokenSymbol
      : "",
    sourceLedgercode: fetchData?.SourceLedgercode
      ? fetchData?.SourceLedgercode
      : "",
    targetLedgercode: fetchData?.TargetLedgercode
      ? fetchData?.TargetLedgercode
      : "",
    // sourceUnits: unitsdataValue ? unitsdataValue : 0,
    sourceUnits: SourceAmountData ? SourceAmountData : 0,
  };
  console.log(" initialValues", initialValues);

  const data = {
    tokenSymbol: "",
    sourceLedgerBalanceID: sourceData?.LedgerBalanceID
      ? sourceData?.LedgerBalanceID
      : 0,
    targetLedgerBalanceID: targetData?.LedgerBalanceID
      ? targetData?.LedgerBalanceID
      : 0,
  };

  const FetchCallDataAPI = async () => {
    let dataDictionary = await axios.post(
      ADMIN_URL + "/common/FetchCallData",
      data
    );

    setFetchData(Object.assign(dataDictionary.data[0]));
    return dataDictionary.data;
  };

  useEffect(() => {
    FetchCallDataAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let APPID = fetchData ? fetchData.SourcePublicAddress : 0;
  let APIKEY = fetchData ? fetchData.SourcePrivateKey : 0;
  // const publicKeys = useSelector((state) => state.keyReducer);
  console.log("🚀 ~ file: Money.js ~ line 87 ~ closeModal ~ APPID", APPID);
  console.log("🚀 ~ file: Money.js ~ line 89 ~ closeModal ~ APIKEY", APIKEY);

  const closeModal = async () => {
    // if (publicKeys.publicKey) {
    //   APPID = publicKeys.publicKey.publicAddress;
    // }
    // if (publicKeys.privateKey) {
    //   APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    // }

    if (APPID && APIKEY) {
      // dispatch(showLoader(true));
      let encryptedIssuer;

      console.log("paymentData.MoneyTransferCommand====", fetchData);
      // TransferAPI

      if (fetchData && fetchData?.MoneyTransferCommand === "Transfer") {
        // dispatch(showLoader(true));
        encryptedIssuer = await CreateTrasferApi(APPID, APIKEY, initialValues);
        console.log("🚀 ~ file: Review.js ~ line 119 ~ closeModal ~ encryptedIssuer", encryptedIssuer)
        const devryptedIssuer = await decrypytdata(
          encryptedIssuer.data,
          APIKEY
        );
        console.log("🚀 ~ file: Review.js ~ line 124 ~ closeModal ~ devryptedIssuer", devryptedIssuer)
        let jsonData;
        if (devryptedIssuer) {
          console.log("🚀 ~ file: Review.js ~ line 132 ~ closeModal ~ encryptedIssuer", encryptedIssuer)
          jsonData = JSON.parse(devryptedIssuer);
          console.log("JSONDATA", jsonData);
          // dispatch(setPrivateId(""));
          dispatch(setResult(jsonData));
          dispatch(setResponseAPI(encryptedIssuer));
          navigate("/result");
        }
      }

      // DepositAPI

      if (fetchData && fetchData?.MoneyTransferCommand === "Deposit") {
        // dispatch(showLoader(true));
        encryptedIssuer = await DepositAPI(APPID, APIKEY, initialValues);
        console.log(
          "🚀 ~ file: Review.js ~ line 100 ~ closeModal ~ encryptedIssuer",
          encryptedIssuer
        );
        const devryptedIssuer = await decrypytdata(
          encryptedIssuer.data,
          APIKEY
        );
        console.log("🚀 ~ file: Review.js ~ line 147 ~ closeModal ~ devryptedIssuer", devryptedIssuer)
        let jsonData;
        if (devryptedIssuer) {
          jsonData = JSON.parse(devryptedIssuer);
          console.log("JSONDATA", jsonData);
          // dispatch(setPrivateId(""));
          dispatch(setResult(jsonData));
          dispatch(setResponseAPI(encryptedIssuer));
          navigate("/result");
        }
      }

      // WithdrawAPI
      if (fetchData && fetchData?.MoneyTransferCommand === "Withdraw") {
        // dispatch(showLoader(true));
        encryptedIssuer = await WithdrawAPI(APPID, APIKEY, initialValues);
        const devryptedIssuer = await decrypytdata(
          encryptedIssuer.data,
          APIKEY
        );
        let jsonData;
        if (devryptedIssuer) {
          jsonData = JSON.parse(devryptedIssuer);
          console.log("JSONDATA", jsonData);
          // dispatch(setPrivateId(""));
          dispatch(setResult(jsonData));
          dispatch(setResponseAPI(encryptedIssuer));
          navigate("/result");
        }
      }

      // const devryptedIssuer = await decrypytdata(encryptedIssuer.data, APIKEY);
      // let jsonData;
      // if (devryptedIssuer) {
      //   jsonData = JSON.parse(devryptedIssuer);
      //   console.log("JSONDATA", jsonData);
      //   // dispatch(setPrivateId(""));
      //   dispatch(setResult(jsonData));
      //   dispatch(setResponseAPI(encryptedIssuer));
      //   navigate("/result");
      // }
      // if (jsonData.ReturnMessage) {
      //   success(jsonData.ReturnMessage);
      // }
      // dispatch(setPrivateId(""));
    }
    // dispatch(showLoader(false));

    // dispatch(showLoader(false));
    // setTimeout(() => {
    //   dispatch(showLoader(false));
    // }, 5000);
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    if (step === 3) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [step]);

  return (
    <>
      {fetchData && (
        <div className="row">
          <div className="col-12">
            <div className="row mt-5">
              <div className="col-sm-6 col-xl-3 mb-4 mb-xl-0">
                <div className="beneficiary-box ">
                  <h3>Originating Account</h3>
                  <label className="mb-0">Digital Currency Account</label>
                  <p>
                    {sourceData.DigitalCurrencyAccount
                      ? sourceData?.DigitalCurrencyAccount
                      : sourceData.Displayname}
                  </p>
                  <label className="mb-0">Public Address</label>
                  <p>{fetchData?.SourcePublicAddress}</p>
                  <label className="mb-0">Currency Code</label>
                  <p>{sourceData.Currencycode}</p>
                  <div className="d-flex position-relative">
                    <div>
                      <label className="mb-0">Token Symbol</label>
                      <p>{fetchData?.SourceTokenSymbol}</p>
                    </div>
                    <div className="Deposit-Review ms-5 position-absolute">
                      <img
                        src={`/images/coins/${fetchData?.SourceTokenSymbol}.png`}
                        // src="../images/coins/U-logo.png"
                        alt="logo"
                      />
                    </div>
                  </div>
                  <label className="mb-0">Transfer Amount</label>
                  <p>{paymentData?.TargetAmount.toFixed(6)}</p>
                </div>
              </div>

              <div className="col-sm-6 col-xl-3 mb-4 mb-xl-0">
                <div className="beneficiary-box payment-box">
                  <h3>Beneficiary Account</h3>
                  <label className="mb-0">Digital Currency Account</label>
                  <p>
                    {targetData?.TargetPublicAddress
                      ? targetData?.TargetPublicAddress
                      : targetData.Displayname}
                  </p>
                  <label className="mb-0">Public Address</label>
                  <p>{fetchData.TargetPublicAddress}</p>
                  <label className="mb-0">Currency Code</label>
                  <p>{targetData.Currencycode}</p>

                  <div className="d-flex position-relative">
                    <div>
                      <label className="mb-0">Token Symbol</label>
                      <p>{fetchData.TargetTokenSymbol}</p>
                    </div>
                    <div className="Deposit-Review ms-5 position-absolute">
                      <img
                        src={`/images/coins/${fetchData?.TargetTokenSymbol}.png`}
                        // src="../images/coins/U-logo.png"
                        alt="logo"
                      />
                    </div>
                  </div>

                  <label className="mb-0">Amount</label>
                  <p>{SourceAmountData?.toFixed(6)}</p>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3 mb-4 mb-xl-0">
                <div className="beneficiary-box rates-box">
                  <h3>Rates & Fee</h3>
                  <label className="mb-0">Service Fee</label>
                  <p>0.00</p>
                  <label className="mb-0">FX Rate</label>
                  <p>{fetchData?.FXRate}</p>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3 mb-4 mb-xl-0">
                <div className="beneficiary-box reference-box">
                  <h3>Reference</h3>
                  <label className="mb-0">Reference Code</label>
                  <p></p>
                  <label>Note</label>
                  <p></p>
                  <label className="mb-0">Attachment</label>
                  {/* <a href="/"></a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="col-xxl-11">
        <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
          <button
            type="button"
            className="btn cancel-btn mb-3 mb-md-0 me-4"
            onClick={previous}
          >
            PREVIOUS
          </button>
          <button
            type="button"
            className="btn next-btn mb-3 mb-md-0"
            onClick={() => {
              closeModal();
            }}
          >
            SUBMIT
          </button>
        </div>
      </div>
    </>
  );
}
export default Money;
