import React from "react";
import { Link } from "react-router-dom";
import { GridActionsCellItem } from "@mui/x-data-grid";

export const Limitscolumns = [
  {
    field: "Displayname",
    headerName: "Settlement Service Provider",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "TokenSymbol",
    headerName: "Symbol",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "SSPTypecode",
    headerName: "SSP Type",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Objectcode",
    headerName: "SSP Code",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  // {
  //   field: "Status",
  //   headerName: "Status",
  //   flex: 1,
  //   type: "string",
  //   align: "right",
  //   headerAlign: "right",
  // },
  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem className="icons-action"
        icon={
          <Link to={`/webwallet/modifylimit?id=${params.row.Objectcode}`}>
            {/* <Link to={`/webwallet/modifytoken`}> */}
          
              <img
                src="../../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
       
          </Link>
        }
        label="Edit"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem className="icons-action"
        icon={
          <Link to={`/webwallet/suspendlimit?id=${params.row.Objectcode}`} >
          
              <img
                src="../../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
          
          </Link>
        }
        label="Delete"
        // onClick={alert("delete")}
      />,
    ],
  },
];

