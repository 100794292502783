import { Link } from "react-router-dom";
function Sidebar() {
    return (
        <>
                <div className="accordion">
                    <div className="sidebar-main" id="accordionissuers">
                        <div className="accordion-item">
                            <h2 className="accordion-header " id="issuers">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" >
                                    <div className="sidebar-main-label d-flex align-items-center active">
                                      
                                        <div className="menu-icon">
                                            <img
                                                src="../icons/System-icon.png"
                                                className="system-icon-img"
                                                alt="system-icon-img"
                                            ></img>
                                        </div>
                                        <h3>System</h3>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="issuers" data-bs-parent="#accordionissuers">
                                <ul>
                                    <li>Configuration</li>
                                    <li>Lookup Tables</li>
                                    <li>Import IP Addresses</li>
                                    <li>Import Accuity Banks</li>
                                    <li>Import FX Rates</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar-main" id="accordionwallets">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="wallets">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" >
                                    <div className="sidebar-main-label d-flex align-items-center">
                                        <div className="menu-icon">
                                            <img
                                                src="../icons/Global Banking.png"
                                                alt="Global-Banking-icon-img"
                                            ></img>
                                        </div>
                                        <h3>Global Banking</h3>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapse8" className="accordion-collapse collapse" aria-labelledby="wallets" data-bs-parent="#accordionwallets" >
                                <ul>
                                    <li> Routing Systems</li>
                                    <li>Clearing Systems</li>
                                    <li>Routing Codes</li>
                                    <li>Banks</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar-main" id="accordionwallets">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="wallets">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" >
                                    <div className="sidebar-main-label d-flex align-items-center">
                                        <div className="menu-icon">
                                            <img
                                                src="../icons/U-icon.png"
                                                alt="U-icon-img"
                                            ></img>
                                        </div>
                                        <h3>UNICOIN</h3>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapse9" className="accordion-collapse collapse" aria-labelledby="wallets" data-bs-parent="#accordionwallets" >
                                <ul>
                                    <li>Issuer Type</li>
                                    <li>Ledger Class</li>
                                    <li>Ledger Type</li>
                                    <li>Token Class</li>
                                    <li>Token Type</li>
                                    <li>Wallet Purpose</li>
                                    <li>Wallet Class</li>
                                    <li>Wallet Type</li>
                                    <li>Wallet Links</li>
                                    <li>Wallet Access</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar-main" id="accordionwallets">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="wallets">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" >
                                    <div className="sidebar-main-label d-flex align-items-center">
                                        <div className="menu-icon">
                                            <img
                                                src="../icons/Groups-Users-icon.png"
                                                className="groups-users-icon-img"
                                                alt="groups-users-icon-img"
                                            ></img>
                                        </div>
                                        <h3>Groups</h3>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="wallets" data-bs-parent="#accordionwallets" >
                                <ul>
                                    <li> <Link to="/AddGroup">Admin Groups</Link></li>
                                    <li>Member Groups</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar-main" id="accordiontoken">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="token">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" >
                                    <div className="sidebar-main-label d-flex align-items-center">
                                        <div className="menu-icon">
                                            <img
                                                src="../icons/user.png"
                                                alt="user-icon-img"
                                            ></img>
                                        </div>
                                        <h3>Users</h3>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="token" data-bs-parent="#accordiontoken">
                                <ul>
                                    <li>Admin Users</li>
                                    <li>Member Users</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar-main" id="accordionledgers">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="ledgers">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" >
                                    <div className="sidebar-main-label d-flex align-items-center">
                                        <div className="menu-icon">
                                            <img
                                                src="../icons/Membership-icon.png"
                                                className="membership-icon-img"
                                                alt="membership-icon-img"
                                            ></img>
                                        </div>
                                        <h3>Membership</h3>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="ledgers" data-bs-parent="#accordionledgers" >
                                <ul>
                                    <li>Configurations</li>
                                    <li>Members</li>
                                    <li>Events</li>
                                    <li>News</li>
                                    <li>Activity Review</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar-main" id="accordionauthorizations">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="authorizations" >
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5">
                                    <div className="sidebar-main-label d-flex align-items-center">
                                        <div className="menu-icon">
                                            <img
                                                src="../icons/KYC-icon.png"
                                                className="kyc-icon-img"
                                                alt="KYC-icon-img"
                                            ></img>
                                        </div>
                                        <h3>KYC</h3>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="authorizations" data-bs-parent="#accordionauthorizations" >
                                <ul>
                                    <li>Configurations</li>
                                    <li>People</li>
                                    <li>Entities</li>
                                    <li>Activity Review</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar-main" id="accordionmoney">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="money">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6">
                                    <div className="sidebar-main-label d-flex align-items-center">
                                        <div className="menu-icon">
                                            <img
                                                src="../icons/CBDC-icon.png"
                                                className="cbdc-icon-img"
                                                alt="cbdc-icon-img"
                                            ></img>
                                        </div>
                                        <h3>CBDC</h3>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="money" data-bs-parent="#accordionmoney">
                                <ul>
                                    <li>Configurations</li>
                                    <li>Digital Currencies</li>
                                    <li>FX Rates</li>
                                    <li>Activity Review</li>
                                    <li>Ledgers</li>
                                    <li>Tokens</li>
                                    <li>Wallets</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar-main" id="accordionrates">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="rates">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" >
                                    <div className="sidebar-main-label d-flex align-items-center">
                                        <div className="menu-icon">
                                            <img
                                                src="../icons/GDPC-icon.png"
                                                className="gdpc-icon-img"
                                                alt="gdpc-icon-img"
                                            ></img>
                                        </div>
                                        <h3>GDPC</h3>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapse7" className="accordion-collapse collapse" aria-labelledby="rates" data-bs-parent="#accordionrates" >
                                <ul>
                                    <li>Configurations</li>
                                    <li>GDP Coins</li>
                                    <li>Activity Review</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
}

export default Sidebar;