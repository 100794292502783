import React from "react";
import PayDataGrid from "../../../../Components/PayDataGrid";
import Footer from "../Footer";
import Header from "../Header";

const ClearingSystem = () => {
  return (
    <>
    <Header/>
      <div className="col-md-9 col-lg-9 col-xxl-10 clearinghed mt-5">
        <div className="container-fluid p-0 ">
          <div className="row ms-lg-4 ms-xl-5 m-0">
            <div className="col-12"></div>
            <div className="main-heading">
              <h3 className="mb-2">SWIFT CODES</h3>
              <p>This page lists the SWIFT CODES registered on the network.</p>
            </div>
          </div>
        </div>
      </div>
      <PayDataGrid props={"LKSWIFTCODES"} />
      <Footer/>
    </>
  );
};

export default ClearingSystem;
