import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { showLoader } from "../../../../src/action";
import Pagination from "../../../Components/NewPagination";
import { FetchEntity} from "../../../API/MoneyServices";
import React, { useEffect, useState } from "react";

function Entity({ setTargetData, nextstep,userData }) {
  // const [isValidPublicAddress, setisValidPublicAddress] = useState(false);

  const publicKeys = useSelector((state) => state.keyReducer);
  const [findEntity, setFindEntity] = useState("");
  const [maxRows, setMaxRows] = useState("");
  const [value, setValues] = useState("");
  console.log("🚀 ~ file: ledger.js ~ line 25 ~ Ledger ~ value", value);

  const [rows, setRows] = React.useState([]);
  //paginason

  const dispatch = useDispatch();
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [postparPage, setPostparPage] = useState(25);

  let APPID = "";
  let APIKEY = "";
  const fetchdata = async () => {
    if (userData.PublicAddress) {
      APPID = userData.PublicAddress;
    }
    if (userData.PrivateKey) {
      APIKEY = userData.PrivateKey;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      let values;
      if (findEntity) {
        values = {
          sourcePublicAddress: APPID,
          entitySearch: findEntity,
          itemcount: postparPage,
          pageId: currentPage,
        };
      } else {
        values = {
          sourcePublicAddress: APPID,
          itemcount: postparPage,
          pageId: currentPage,
        };
      }

      // const grid = { itemcount: 10, pageId: 1 };
      const encryptedLedger = await FetchEntity(APPID, APIKEY, values);
      // const tokens = await decrypytdata(encryptedLedger.data, APIKEY);

      // const data = JSON.parse(JSON.parse(tokens).CommandResponse);
      // const data = JSON.parse(tokens);
      let data = encryptedLedger.data;

      if (data) {
        let dataArr = [];
        let temp = data.shift();

        if (temp) {
          setMaxRows(temp.MaxRows);
        }
        data?.forEach((dataMain, index) => {
          // multiple data
          dataArr.push({ ...dataMain, rowid: index });
        });
        setRows(dataArr);
      }
    }
    dispatch(showLoader(false));
  };

  //   const fetchdropdwon = async () => {
  //     let lclass = await getDropdownvalues("LKLedgerClass");
  //     setLedgerClass(lclass);

  //     // let dropdownvalues = await getDropdownvalues("LKLedgerType");
  //     // setLedgerTypedropdown(dropdownvalues);
  //     // let dialing = await getDailingDropDown("GetDialingcodes");
  //     // setDailingCode(dialing);
  //   };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        // setisValidPublicAddress(true);
        // fetchdropdwon();
        fetchdata();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  useEffect(() => {
    fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findEntity, postparPage, currentPage]);

  useEffect(() => {
    setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postparPage]);

  const [checkedRows, setCheckedRows] = useState();

  const initialValues = {};
  return (
    <>
      {/* {isValidPublicAddress && ( */}
        <div>
          <div className="row mt-5">
            <div className="col-xxl-11">
              <Formik
                initialValues={initialValues}
                // validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                  console.log(
                    "🚀 ~ file: CreateIssuer.js ~ line 174 ~ CreateIssuer ~ values",
                    values
                  );
                  // debugger;
                  // CreateIssuersApi(values);
                }}
              >
                {(formik) => {
                  return (
                    <div className="wallet-balances-box">
                      <div className="d-md-flex justify-content-end">
                        <div className="">
                          <div className="line-input input-group mb-3">
                            <span className="input-group-text">
                              <i className="bi bi-search"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="search"
                              onChange={(e) => {
                                setFindEntity(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className=" ">
                        <Paper
                          sx={{
                            width: "100%",
                            overflow: "hidden",
                            border: 1,
                            borderColor: "#c6c6c6",
                          }}
                        >
                          <TableContainer
                            component={Paper}
                            sx={{
                              boxShadow: "none",
                              maxHeight: 500,
                              borderBottom: 1,
                              borderColor: "#c6c6c6",
                            }}
                          >
                            <Table
                              stickyHeader
                              className="table table-hover table-striped"
                              sx={{ minWidth: 650, mb: 0 }}
                              aria-label="simple table"
                            >
                              <TableHead
                                className="sticky-top"
                                sx={{
                                  zIndex: "10",
                                  borderBottom: 1,
                                  borderColor: "#c6c6c6",
                                  backgroundColor:"#fff",
                                }}
                              >
                                <TableRow sx={{ height: "1px" }}>
                                  <TableCell sx={{ height: "inherit" }}>
                                    <Box sx={{ height: "100%" }}>
                                      <label className="table-lable">
                                        Select
                                      </label>
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <label className="table-lable">
                                      Merchant
                                    </label>
                                  </TableCell>
                                  <TableCell>
                                    <label className="table-lable">
                                      Digital Currency Account
                                    </label>
                                  </TableCell>
                                  <TableCell>
                                    <label className="table-lable">
                                      Countrycode
                                    </label>
                                  </TableCell>
                                  <TableCell>
                                    <label className="table-lable">
                                      StateProvince
                                    </label>
                                  </TableCell>
                                  <TableCell>
                                    <label className="table-lable">
                                      CityTown
                                    </label>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {/* {rows?.length > 0 &&
                    rows
                      ?.slice(indexofFirstpost, indexofLastpage)
                      ?.filter((user) => {
                        if (
                          tokenClassData === "" &&
                          bankclassItem === "" &&
                          tokenTypeData === ""
                        ) {
                          return user;
                        } else if (
                          user.tokenClass
                            .toLowerCase()
                            .includes(tokenClassData.toLowerCase()) &&
                          user.currencycodeList
                            .toLowerCase()
                            .includes(bankclassItem.toLowerCase()) &&
                          user.tokenType
                            .toLowerCase()
                            .includes(tokenTypeData.toLowerCase())
                        ) {
                          return user;
                        }
                      }) */}
                                {rows?.map((value, index) => {
                                  return (
                                    <>
                                      <TableRow
                                        onDoubleClick={() => {
                                          setTargetData(value);
                                          nextstep();
                                        }}
                                      >
                                        <TableCell sx={{position:"relative"}}>
                                          <div className=" table-form-check ">
                                            <input
                                              className="form-check-input ml-0"
                                              type="checkbox"
                                              id="flexCheckDefault"
                                              checked={
                                                checkedRows === value.RowID
                                              }
                                              // label={}
                                              onChange={(e) => {
                                                console.log(
                                                  e.target.checked,
                                                  "checked"
                                                );
                                                setCheckedRows(value.RowID);
                                                if (e.target.checked === true) {
                                                  setValues(value);
                                                  setTargetData(value);
                                                }
                                              }}
                                              name="flexCheckDefault"
                                            />
                                          </div>
                                        </TableCell>
                                        <TableCell>
                                          {value.Displayname}
                                        </TableCell>
                                        <TableCell>
                                          {value.DigitalCurrencyAccount}
                                        </TableCell>
                                        <TableCell>
                                          {value.Countrycode}
                                        </TableCell>
                                        <TableCell>
                                          {value.StateProvince}
                                        </TableCell>
                                        <TableCell>{value.CityTown}</TableCell>
                                      </TableRow>
                                    </>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <Box sx={{ width: "100%" }}>
                            <Box className="row py-3 px-2">
                              <Box className="col-12 col-md-3 col-xl-2">
                                <div className="row-select">
                                  <select
                                    class="form-select pagination-select"
                                    aria-label="Default select example"
                                    onChange={(e) => {
                                      setPostparPage(e.target.value);
                                    }}
                                    // placeholder="Bank Type"
                                  >
                                    {/* <option selected>Select rows</option> */}

                                    <option value={10}>10 Rows</option>
                                    <option selected value={25}>
                                      25 Rows
                                    </option>
                                    <option value={50}>50 Rows</option>
                                    <option value={100}>100 Rows</option>
                                    <option value={200}>200 Rows</option>
                                  </select>
                                </div>
                              </Box>

                              <Box className="col-12 col-xl-10 col-md-9 d-flex justify-content-end align-items-center">
                                <Pagination
                                  postsperPage={postparPage}
                                  maxRows={maxRows}
                                  paginate={paginate}
                                  currentPage={currentPage}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Paper>
                        {/* <Pagination
            postsperPage={postparPage}
            totalPost={rows?.length}
            paginate={paginate}
            currentPage={currentPage}
          /> */}
                      </div>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      {/* )} */}
    </>
  );
}
export default Entity;
