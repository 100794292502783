import "./assets/css/bootstrap.min.css";
import "./assets/css/main.min.css";
import "./assets/css/styles.css";
import "./App.css";
import "react-telephone-input/css/default.css";
// import Home from "../src/Views/admin/SysAdmin/index";
import GetIssures from "./Views/admin/Issures/GetIssures";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import routes from "./routes";
import MoneyTransfer from "./Pages/money-transfer/wallettowallet";
import Result from "./Components/Result";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((el, i) => {
          return (
            <Route exact path={el.path} element={<el.element />} key={i} />
          );
        })}

        <Route path="*" element={<Navigate replace to="/" />} />
              <Route path="/admin/get-issures" exact element={<GetIssures />} />
              {/* <Route path="/sysadmin" exact element={<Home />} /> */}
              <Route path="/money-transfer-demo" exact element={<MoneyTransfer />} />
              <Route path="/result" exact element={<Result />} />
          </Routes>

    </BrowserRouter>
  );
}

export default App;
