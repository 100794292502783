//import ReactHelmet from "../../Components/Utilities/ReactHelmet";
// import { useState } from "react";
import { LogInAPI } from "../../API/LogIn";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { decrypytdata } from "../../API/walletkey";
import { useNavigate } from "react-router-dom";
import { DecryptionString } from "../../Constants/Config";

const Login = () => {
  const [userData, SetUserData] = useState("");
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
  };

  const LogInHandler = async (values) => {
    const LogInData = await LogInAPI(values);
    console.log(
      "🚀 ~ file: Login.js ~ line 28 ~ LogInHandler ~ LogInData",
      LogInData
    );
    SetUserData(LogInData);

    const devryptedToken = await decrypytdata(LogInData.data, DecryptionString);
    const jsonData = JSON.parse(devryptedToken);
    console.log(
      "🚀 ~ file: Login.js ~ line 40 ~ LogInHandler ~ jsonData",
      jsonData
    );
    const logInDataValues = jsonData?.CommandResponse[0];
    console.log("Dfdsknfdkjnfdzknklnz====", logInDataValues);
    // CommandResponse[0].UserRoleKeycode
    // SetcommandResponseData(jsonData.CommandResponse[0]);

    if (logInDataValues.UserRoleKeycode === "PORTAL-SA") {
      window.localStorage.setItem("UserLogIn", JSON.stringify(logInDataValues));
      navigate("/system-administrator");
    } else if (logInDataValues.UserRoleKeycode === "BANK-SA") {
      window.localStorage.setItem("UserLogIn", JSON.stringify(logInDataValues));
      navigate("/bank-administrator");
    } else if (logInDataValues.UserRoleKeycode === "ISSUER-SA") {
      window.localStorage.setItem("UserLogIn", JSON.stringify(logInDataValues));
      navigate("/issuer-administrator");
    } else if (logInDataValues.UserRoleKeycode === "CRYPTO-SA") {
      window.localStorage.setItem("UserLogIn", JSON.stringify(logInDataValues));
      navigate("/crypto-administrator");
    } else {
      window.localStorage.setItem("UserLogIn", JSON.stringify(logInDataValues));
      navigate("/issuer-administrator");
    }
  };

  // useEffect(() => {
  //   if (
  //     commandResponseData &&
  //     commandResponseData.UserRoleKeycode === "PORTAL-SA"
  //   ) {
  //     window.localStorage.setItem(
  //       "UserLogIn",
  //       JSON.stringify(commandResponseData)
  //     );
  //   }
  // }, [commandResponseData]);

  return (
    <>
      {/*<ReactHelmet title="Login" description="" />
      <div className="transfer-rates-page">
                <div className="transfer-rates">
                    <div className="row transfer-rates-page">
                        <div className="col-xxl-11 ">
                            <div className="main-head">
                                <i className="bi bi-arrow-left"></i>
                              <button><a href="/register">Login</a></button>
                            </div>
                     
                        </div>
                    </div>
                </div>
            </div>*/}
      <div className="container-fluid">
        <div className="login-page">
          <div className="row align-items-center">
            <div className="col-md-7 col-lg-auto p-0">
              <div className="login-img">
                <img
                  src="../images/dl.beatsnoop 1.png"
                  className="img-fluid"
                  alt="login-img"
                />
              </div>
            </div>
            <div className="col-md-5 col-lg-4">
              <Formik
                initialValues={initialValues}
                // validationSchema={validationSchema}
                // enableReinitialize={true}
                onSubmit={(values) => {
                  console.log(
                    "🚀 ~ file: Login.js ~ line 72 ~ Login ~ values",
                    values
                  );
                  //   SetUserData(values);
                  LogInHandler(values);
                }}
              >
                {(formik) => {
                  return (
                    <>
                      <div className="login-box">
                        <div className="login-text">
                          <img src="images/U-1.png" alt="logo" />
                          <h3>Welcome Back</h3>
                          <Form>
                            <div className="mb-3 mb-md-5">
                              <label className="form-label">
                                Email Address
                              </label>
                              <div className="password-eye">
                                <Field
                                  type="text"
                                  className="form-control"
                                  id="email"
                                  name="email"
                                  //   onChange={(e) => {
                                  //     SetUserData({ email: e.target.value });
                                  //   }}
                                />
                                {/* <img src="../icons/x.png" alt="CroodsChart" className="eye-img" /> */}
                              </div>
                              <div style={{ color: "red" }}>
                                <ErrorMessage
                                  name="email"
                                  component="span"
                                  className="error"
                                />
                              </div>

                              {/* <p>Email Address is required</p> */}
                            </div>
                            <div className="mb-3">
                              <label className="form-label">Password</label>
                              <div className="password-eye">
                                <Field
                                  type="text"
                                  className="form-control"
                                  id="password"
                                  name="password"
                                  //   onChange={(e) => {
                                  //     SetUserData({ password: e.target.value });
                                  //   }}
                                />
                                <img
                                  src="../icons/eye.png"
                                  alt="CroodsChart"
                                  className="eye-img"
                                />
                              </div>
                              <div style={{ color: "red" }}>
                                <ErrorMessage
                                  name="password"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              {/* {jsonData && jsonData.ReturnMessage === "Invalid Password" ? (
                                <p>Invalid password </p> 
                              ): ""} */}
                              {userData && userData.status !== 200 ? (
                                <p>Invalid password </p>
                              ) : (
                                " "
                              )}
                            </div>
                            <div className="form-check">
                              <div className="">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="Check"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Check"
                                >
                                  Remember me
                                </label>
                              </div>
                              <a href="/register">Forgot Password</a>
                            </div>
                            <button
                              type="submit"
                              className="btn"
                              //   onClick={() => {
                              //     // LogInHandler(userData);
                              //   }}
                            >
                              {/* <a href="/register">SIGN IN</a> */}
                              SIGN IN
                            </button>
                          </Form>
                        </div>
                      </div>
                    </>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
