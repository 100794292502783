import { ADMIN_URL, WEB_WALLET_URL } from "../Constants/Config";
import http from "../API/httpService";
import { httpAdmin } from "./httpService";
import { hmacencrypt } from "../API/walletkey";

export async function CreateTrasferApi(APPID, APIKEY, data) {
  // debugger;
  // let values = {
  //   ...tokendata,
  //   // sourcePublicAddress: publickey,
  // };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/money/Transfer",
    data,
    APPID,
    APIKEY
  );
  const response = await http.post(
    "/money/Transfer",
    data,

    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function DepositAPI(APPID, APIKEY, data) {
  console.log("API-Data", data);
  // debugger;
  // let values = {
  //   ...tokendata,
  //   // sourcePublicAddress: publickey,
  // };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/ledger/Deposit",
    data,
    APPID,
    APIKEY
  );
  const response = await http.post(
    "/ledger/Deposit",
    data,

    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function WithdrawAPI(APPID, APIKEY, data) {
  console.log("API-Data", data);
  // debugger;
  // let values = {
  //   ...tokendata,
  //   // sourcePublicAddress: publickey,
  // };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/ledger/Withdraw",
    data,
    APPID,
    APIKEY
  );
  const response = await http.post(
    "/ledger/Withdraw",
    data,

    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function FetchTokens(publickey, privateKey, datass) {
  let values = {
    sourcePublicAddress: publickey,
    // currencycode: datass.currencycode,
    tokenClasscode: datass.tokenClasscode,
    tokenTypecode: datass.tokenTypecode,
  };
  const token = await hmacencrypt(
    "POST",
    ADMIN_URL + "/common/FetchTokens",
    values,
    publickey,
    privateKey
  );
  const response = await httpAdmin.post(
    "/common/FetchTokens",
    // { sourcePublicAddress: publickey,tokenSymbol: "UMU" },
    values,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function FetchBanks(publickey, privateKey, datass) {


  let values = {
    sourcePublicAddress: publickey,
    parentBankID: datass.parentBankID,
    bankclasscode: datass.bankclasscode,
    bankTypecode: datass.bankTypecode,
    countrycode: datass.countrycode,
    statecode: datass.statecode,
    citycode: datass.citycode,
    itemcount: datass.itemcount,
    pageId: datass.pageId,
    searchPhrase: datass.searchPhrase
  };
  // let values = {
  //   sourcePublicAddress: publickey,
  //   // parentBankID:"",
  // }
  const token = await hmacencrypt(
    "POST",
    ADMIN_URL + "/common/FetchBanks",
    values,
    publickey,
    privateKey
  );
  const response = await httpAdmin.post(
    "/common/FetchBanks",
    // { sourcePublicAddress: publickey,tokenSymbol: "UMU" },
    values,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function FetchBanksChild(publickey, privateKey, bankID) {
  // let values = {
  //   sourcePublicAddress: publickey,
  //   parentBankID: bankID.parentBankID,
  //   // parentBankID: bankID.bankID,
  //   // bankclasscode: bankID.bankclass,
  //   // bankTypecode: bankID.bankType,
  // };
  // let values = {
  //   sourcePublicAddress: publickey,
  //   // parentBankID:"",
  // }

  const token = await hmacencrypt(
    "POST",
    ADMIN_URL + "/common/FetchBanks",
    bankID,
    publickey,
    privateKey
  );
  const response = await httpAdmin.post(
    "/common/FetchBanks",
    // { sourcePublicAddress: publickey,tokenSymbol: "UMU" },
    bankID,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function FetchLedgers(publickey, privateKey, data) {
  // let values = {
  //   sourcePublicAddress: publickey,
  //   ledgerClasscode:data.ledgerClasscode,
  // };
  //   let newvalue ={
  //     sourcePublicAddress: publickey,
  //     ledgerClasscode
  // :
  // "RESERVED",
  // ledgerTypecode
  // :
  // "ECASH"
  //   }
  const token = await hmacencrypt(
    "POST",
    ADMIN_URL + "/common/FetchLedgers",
    data,
    publickey,
    privateKey
  );
  const response = await httpAdmin.post(
    "/common/FetchLedgers",
    // { sourcePublicAddress: publickey,tokenSymbol: "UMU" },
    data,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function FetchLedgersChild(publickey, privateKey, data) {
  // let values = {
  //   sourcePublicAddress: publickey,
  //   ledgerClasscode:data.ledgerClasscode,
  // };
  //   let newvalue ={
  //     sourcePublicAddress: publickey,
  //     ledgerClasscode
  // :
  // "RESERVED",
  // ledgerTypecode
  // :
  // "ECASH"
  //   }
  const token = await hmacencrypt(
    "POST",
    ADMIN_URL + "/common/FetchLedgers",
    data,
    publickey,
    privateKey
  );
  const response = await httpAdmin.post(
    "/common/FetchLedgers",
    // { sourcePublicAddress: publickey,tokenSymbol: "UMU" },
    data,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function FetchCallDataAPI(publickey, privateKey, data) {
  // debugger;
  // let values = {
  //   ...tokendata,
  //   // sourcePublicAddress: publickey,
  // };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/common/FetchCallData",
    data,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/common/FetchCallData",
    data,

    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}
export async function FetchPeople(publickey, privateKey, data) {
  // let values = {
  //   sourcePublicAddress: publickey,
  //   // ledgerClasscode: data.ledgerClasscode,
  //   // ledgerTypecode: data.ledgerTypecode,
  // };
  //   let newvalue ={
  //     sourcePublicAddress: publickey,
  //     ledgerClasscode
  // :
  // "RESERVED",
  // ledgerTypecode
  // :
  // "ECASH"
  //   }
  const token = await hmacencrypt(
    "POST",
    ADMIN_URL + "/common/FetchPeople",
    data,
    publickey,
    privateKey
  );
  const response = await httpAdmin.post(
    "/common/FetchPeople",
    // { sourcePublicAddress: publickey,tokenSymbol: "UMU" },
    data,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function FetchEntity(publickey, privateKey, data) {
  // let values = {
  //   sourcePublicAddress: publickey,
  //   entitySearch: "Anastasia Butler",
  //   // ledgerTypecode: data.ledgerTypecode,
  // };
  //   let newvalue ={
  //     sourcePublicAddress: publickey,
  //     ledgerClasscode
  // :
  // "RESERVED",
  // ledgerTypecode
  // :
  // "ECASH"
  //   }
  const token = await hmacencrypt(
    "POST",
    ADMIN_URL + "/common/FetchEntity",
    data,
    publickey,
    privateKey
  );
  const response = await httpAdmin.post(
    "/common/FetchEntity",
    // { sourcePublicAddress: publickey,tokenSymbol: "UMU" },
    data,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function FetchExchange(publickey, privateKey, data) {
  // let values = {
  //   sourcePublicAddress: publickey,
  //   searchPhrase: data.searchPhrase,
  //   // ledgerTypecode: data.ledgerTypecode,
  // };
  //   let newvalue ={
  //     sourcePublicAddress: publickey,
  //     ledgerClasscode
  // :
  // "RESERVED",
  // ledgerTypecode
  // :
  // "ECASH"
  //   }
  const token = await hmacencrypt(
    "POST",
    ADMIN_URL + "/common/FetchExchange",
    data,
    publickey,
    privateKey
  );
  const response = await httpAdmin.post(
    "/common/FetchExchange",
    // { sourcePublicAddress: publickey,tokenSymbol: "UMU" },
    data,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}
