import axios from "axios";
import { ADMIN_URL } from "../Constants/Config";

export async function getDropdownvalues(type) {
  let dataDictionary = await axios.post(ADMIN_URL + "/common/GetLookupTable", {
    LookupTable: type,
  });

  return dataDictionary.data;
}

export async function GetReferenceData(type) {
  let dataDictionary = await axios.post(
    ADMIN_URL + "/common/GetReferenceData",
    type
  );

  return dataDictionary.data;
}

export async function GetReferenceTables(type) {
  let dataDictionary = await axios.post(
    ADMIN_URL + "/common/GetReferenceTables",
    {
      dataDictionary: "",
    }
  );

  return dataDictionary.data;
}

export async function GetReferenceParent(type) {
  console.log("ferenceParenttype", type)
  let dataDictionary = await axios.post(
    ADMIN_URL + "/common/GetReferenceParent",
    {
      // dataDictionary: "",
      lookupTable: type,
      // keycode: "",
    }
  );

  return dataDictionary.data;
}
