// import React, { useEffect, useState } from "react";
// import PrivateModal from "../../../Components/PrivateModal";
// import { getIssuer } from "../../../api/Issures";
// import { useDispatch, useSelector } from "react-redux";
// import { decrypytdata } from "../../../api/walletKey";
// import { Issuercolumns } from "../../../Components/GridHeaders";
// import Grid from "../../../Components/Grid";
import { Link } from "react-router-dom";
// import { showLoader } from "../../../action";
function GetIssures() {
  // const [privateModal, setPrivateModal] = useState(false);
  // const [rows, setRows] = React.useState([]);
  // const publicKeys = useSelector((state) => state.keyReducer);
  // let APPID = "";
  // let APIKEY = "";
  // const dispatch = useDispatch();
  // const closeModal = () => {
  //   setPrivateModal(false);
  // };
  // const fetchdata = async () => {
  //   if (publicKeys.publicKey) {
  //     APPID = publicKeys.publicKey.publicAddress;
  //   }
  //   if (publicKeys.privateKey) {
  //     APIKEY = publicKeys.privateKey.PrivateWalletAddress;
  //   }
  //   if (APPID && APIKEY) {
  //     dispatch(showLoader(true));
  //     const encryptedIssuer = await getIssuer(APPID, APIKEY);
  //     const Issuer = await decrypytdata(encryptedIssuer?.data, APIKEY);

  //     let data = JSON.parse(JSON.parse(Issuer).CommandResponse);
  //     console.log(
  //       "🚀 ~ file: IssuresBalances.js ~ line 30 ~ fetchdata ~ data",
  //       data
  //     );

  //     if (data) {
  //       let dataArr = [];
  //       data?.forEach((dataMain, index) => {
  //         dataArr.push({
  //           ...dataMain,
  //           rowid: dataMain.Issuercode,
  //         });
  //       });
  //       setRows(dataArr);
  //     }
  //   }
  //   dispatch(showLoader(false));
  // };

  // useEffect(() => {
  //   if (publicKeys?.publicKey?.publicAddress) {
  //     if (!publicKeys?.privateKey?.PrivateWalletAddress) setPrivateModal(true);
  //   }
  //   fetchdata();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [publicKeys, privateModal]);
  return (
    <>
      {/* {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
      )} */}
      <div className="col-md-9 col-lg-9 col-xxl-10">
        <div className="row  ms-lg-4 ms-xl-5">
          <div className="col-xxl-10">
            <div className="main-heading">
              <h3> Issuers</h3>
              <h6> Get Issuers</h6>
              <p>
                This function lists all the issuers associated with a wallet.
              </p>
            </div>
            <div className="wallet-balances-box">
              <div className="d-md-flex justify-content-between ">
                <div className="search-box d-md-flex">
                  <div className="input-group mb-3"></div>
                </div>
                <Link to={{ pathname: "/webwallet/createissuer" }}>
                  <button
                    type="button"
                    className="btn btn-primary "
                    style={{ marginBottom: "10px" }}
                  >
                    + ADD Issuer
                  </button>
                </Link>
              </div>
              <div style={{ height: 800, width: "100%" }}>
                {/* <Grid row={rows} coloums={Issuercolumns} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GetIssures;
