import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import {
  GetReferenceData,
  GetReferenceParent,
  GetReferenceTables,
} from "../../API/dropdown";
import Pagination from "../../Components/NewPagination";
// import showLoader from "../../action/index"
function DataDictionary({ setsourceData, nextstep }) {
  // const publicKeys = useSelector((state) => state.keyReducer);
  // const dispatch = useDispatch()
  const [parentData, setParentData] = useState();
  const [parentAPIData, setParentAPIData] = useState();
  const [checkedRows, setCheckedRows] = useState([]);
  const [lookupData, setLookupData] = useState([]);
  console.log(" DataDictionary ~ lookupData", lookupData)
  const [rows, setRows] = React.useState([]);
  const [maxRows, setMaxRows] = useState("");
  const [findPeople, setFindPeople] = useState("");
  const [lookupTableData, setLookupTableData] = useState();

  //paginason
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [currentPage, setCurrentPage] = useState(0);
  const [postparPage, setPostparPage] = useState(25);

  // const indexofLastpage = currentPage * postparPage;
  // const indexofFirstpost = indexofLastpage - postparPage;

  const fetchdropdwon = async () => {
    let refrenceDatas = await GetReferenceParent(lookupTableData);
    setParentData(refrenceDatas);
    let LookupTableType = await GetReferenceTables();
    setLookupData(LookupTableType);
    // let LKBankCoutnryType = await getDropdownvalues("LKCentralBanks");
    // setCountry(LKBankCoutnryType);
  };

  const fetchdata = async () => {
    // dispatch(showLoader(true));
    let values;
    if (findPeople || lookupTableData) {
      values = {
        searchPhrase: findPeople,
        itemcount: postparPage,
        pageId: currentPage,
        lookupTable: lookupTableData,
        ParentKeycode: parentAPIData,
      };
    } else {
      values = {
        itemcount: postparPage,
        pageId: currentPage,
      };
    }

    // const ndata = {
    //   ledgerClasscode: "RESERVED",
    //   // ledgerTypecode: findLedgerType,
    // };
    // const grid = { itemcount: 10, pageId: 1 };
    const encryptedLedger = await GetReferenceData(values);
    console.log("encryptedLedger", encryptedLedger);
    // const tokens = await decrypytdata(encryptedLedger.data, APIKEY);
    // const data = JSON.parse(JSON.parse(tokens).CommandResponse);
    // const data = JSON.parse(tokens);
    let data = encryptedLedger;

    if (data) {
      let dataArr = [];
      let temp = data.shift();

      if (temp) {
        setMaxRows(temp.MaxRows);
      }
      data?.forEach((dataMain, index) => {
        // multiple data
        dataArr.push({ ...dataMain, rowid: index });
      });
      setRows(dataArr);
    }

    // dispatch(showLoader(false));
  };

  useEffect(() => {
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postparPage]);

  useEffect(() => {
    fetchdata();
    fetchdropdwon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findPeople, postparPage, currentPage, lookupTableData, parentAPIData]);

  return (
    <>
      <div className="row mt-5">
        <div className="col-xxl-10 mx-auto">
          <div className="wallet-balances-box">
            <div className="d-md-flex justify-content-end px-4">
              <div className="">
                <div className="line-input input-group mb-3">
                  <span className="input-group-text">
                    <i className="bi bi-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="search"
                    onChange={(e) => {
                      setFindPeople(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className=" ">
              <Paper
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  border: 1,
                  borderColor: "#c6c6c6",
                }}
              >
                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: "none",
                    maxHeight: 500,
                    borderBottom: 1,
                    borderColor: "#c6c6c6",
                  }}
                >
                  <Table
                    stickyHeader
                    className="table table-hover table-striped"
                    sx={{ minWidth: 650, mb: 0 }}
                    aria-label="simple table"
                  >
                    <TableHead
                      className="sticky-top"
                      sx={{
                        zIndex: "10",
                        borderBottom: 1,
                        borderColor: "#c6c6c6",
                        backgroundColor: "#fff",
                      }}
                    >
                      <TableRow className="data-table-row">
                        <TableCell className="data-table-head">
                          <div className="lable-div">
                            <span className="text-dark">Select</span>
                          </div>
                        </TableCell>
                        <TableCell className="data-table-head">
                          <div className="lable-div">
                            <span className="text-dark">Lookup Table </span>
                            <select
                              class="form-select"
                              aria-label="Default select example"
                              onChange={(e) =>
                                setLookupTableData(e.target.value)
                              }
                              placeholder="Bank Type"
                            >
                              <option selected>Select Lookup Table Type</option>
                              {lookupData.map((index) => {
                                return (
                                  <>
                                    <option value={index.LookupTable}>
                                      {index.LookupTable}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </TableCell>
                        <TableCell>
                          <span className="text-dark"> Key Code </span>
                        </TableCell>

                        <TableCell className="data-table-head">
                          <div className="lable-div">
                            <span className="text-dark">Displayname</span>
                          </div>
                        </TableCell>
                        <TableCell className="data-table-head">
                          <div className="lable-div">
                            <span className="text-dark">Parent Key Code</span>
                            <select
                              class="form-select"
                              aria-label="Default select example"
                              onChange={(e) => setParentAPIData(e.target.value)}
                              placeholder="Bank Type"
                            >
                              <option selected>Parent Key Code Type</option>
                              {parentData?.map((index) => {
                                return (
                                  <>
                                    <option value={index.Keycode}>
                                      {index.Keycode}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </TableCell>

                        <TableCell className="data-table-head">
                          <div className="lable-div">
                            <span className="text-dark">DataValue1</span>
                          </div>
                        </TableCell>

                        <TableCell className="data-table-head">
                          <div className="lable-div">
                            <span className="text-dark">DataValue2</span>
                          </div>
                        </TableCell>

                        {/* <TableCell className="data-table-head">
                          <div className="lable-div">
                            <span className="text-dark">City Town</span>
                          </div>
                        </TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* {rows?.length > 0 &&
                    rows
                      ?.slice(indexofFirstpost, indexofLastpage)
                      ?.filter((user) => {
                        if (
                          tokenClassData === "" &&
                          bankclassItem === "" &&
                          tokenTypeData === ""
                        ) {
                          return user;
                        } else if (
                          user.tokenClass
                            .toLowerCase()
                            .includes(tokenClassData.toLowerCase()) &&
                          user.currencycodeList
                            .toLowerCase()
                            .includes(bankclassItem.toLowerCase()) &&
                          user.tokenType
                            .toLowerCase()
                            .includes(tokenTypeData.toLowerCase())
                        ) {
                          return user;
                        }
                      }) */}
                      {rows?.map((items, index) => {
                        return (
                          <>
                            <TableRow
                              className={" " + (checkedRows ? "rowselect" : "")}
                              onDoubleClick={() => {
                                console.log("double");
                                setsourceData(items);
                                nextstep();
                              }}
                              // key={index}
                              // onClick={() => setActive(items)}
                              // id={index}
                              // className={isActive === items ? "is-checked" : ""}
                            >
                              {/* <span>{index}</span> */}
                              <TableCell sx={{ position: "relative" }}>
                                <div className=" table-form-check ">
                                  <input
                                    className="form-check-input ml-0"
                                    type="checkbox"
                                    id="flexCheckDefault"
                                    name="flexCheckDefault"
                                    checked={checkedRows === items.RowID}
                                    onChange={() => {
                                      setCheckedRows(items.RowID);
                                      // setInChecked(true);
                                    }}
                                    onClick={() => {
                                      setsourceData(items);
                                    }}
                                  />
                                </div>
                              </TableCell>
                              <TableCell>{items.LookupTable}</TableCell>
                              <TableCell>{items.Keycode}</TableCell>
                              <TableCell>{items.Displayname}</TableCell>
                              <TableCell>{items.ParentKeycode}</TableCell>
                              <TableCell>{items.DataValue1}</TableCell>
                              <TableCell>{items.DataValue2}</TableCell>
                              {/* <TableCell>{items.CityTown}</TableCell> */}
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box sx={{ width: "100%" }}>
                  <Box className="row py-3 px-2">
                    <Box className="col-12 col-md-3 col-xl-2">
                      <div className="row-select">
                        <select
                          class="form-select pagination-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            setPostparPage(e.target.value);
                          }}
                          // placeholder="Bank Type"
                        >
                          {/* <option selected>Select rows</option> */}

                          <option value={10}>10 Rows</option>
                          <option selected value={25}>
                            25 Rows
                          </option>
                          <option value={50}>50 Rows</option>
                          <option value={100}>100 Rows</option>
                          <option value={200}>200 Rows</option>
                        </select>
                      </div>
                    </Box>

                    <Box className="col-12 col-xl-10 col-md-9 d-flex justify-content-end align-items-center">
                      <Pagination
                        postsperPage={postparPage}
                        maxRows={maxRows}
                        paginate={paginate}
                        currentPage={currentPage}
                      />
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default DataDictionary;
