import React from "react";
//import { Link } from "react-router-dom";
function CentralBankDigitalCurrencies() {
    return (
        <>
            <div className="central-bank-digital-currencies py-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 mb-5">
                            <h3>Central Bank Digital Currencies (CBDCs)</h3>
                        </div>
                        <div className="col payment-systems-box mb-5 mb-sm-0">
                            <div className="">
                                <div className="box-icon">
                                    <img src="../icons/dcs.png" className="icons-two" alt="icon" />
                                </div>
                                <h5>DCS</h5>
                            </div>
                        </div>
                        <div className="col payment-systems-box mb-5 mb-sm-0">
                            <div className="">
                                <div className="box-icon">
                                    <img src="../icons/Fees.png" className="icons-two" alt="icon" />
                                </div>
                                <h5>Fees</h5>
                            </div>
                        </div>
                        <div className="col payment-systems-box ">
                            <div className="">
                                <div className="box-icon">
                                    <img src="../icons/limits.png" className="icons-two" alt="icon" />
                                </div>
                                <h5>Limits</h5>
                            </div>
                        </div>
                        <div className=" col payment-systems-box mt-sm-5 mt-md-0">
                            <div className="">
                                <div className="box-icon">
                                    <img src="../icons/rates.png" className="icons-two" alt="icon" />
                                </div>
                                <h5>Fx Rates</h5>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </>
    );
}

export default CentralBankDigitalCurrencies;
