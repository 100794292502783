import Footer from "../../Components/Footer.js/Footer";
import Header from "../../Components/Navbar/Header";
//import ReactHelmet from "../../Components/Utilities/ReactHelmet";
import RegisterPage from "./RegisterPage";
import Sidebar from "../../Components/Sidebar/Sidebar"
const Register = () => {
    return (
        <>
            {/*<ReactHelmet title="Register" description="" />*/}
            <Header />
            <div className="container-fluid p-0">
                <div className="row justify-content-between m-0">
                    <div className="col-md-4 col-lg-3 col-xl-auto p-0 sidebar">
                        <Sidebar />
                    </div>
                    <div className="col-md-8 col-lg-9 col-xl ps-xl-0">
                        <RegisterPage />

                        <div className="row">
                        <div className="col-12">
                            <Footer />
                        </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default Register;
