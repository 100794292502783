import React from "react";
//import { Link } from "react-router-dom";
function ReportingandAnalytics() {
    return (
        <>
            <div className="central-bank-digital-currencies py-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 mb-5">
                            <h3>Reporting and Analytics</h3>
                        </div>
                        <div className="col payment-systems-box mb-0">
                            <div className="">
                                <div className="box-icon">
                                    <img src="../icons/fsp.png" className="icons-two" alt="icon" />
                                </div>
                                <h5>FSPs</h5>
                            </div>
                        </div>
                        <div className="col payment-systems-box mb-0">
                            <div className=" ">
                                <div className="box-icon">
                                    <img src="../icons/volume.png" className="icons-two" alt="icon" />
                                </div>
                                <h5>Volume</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportingandAnalytics;
