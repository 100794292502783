import React from "react";
//import { Link } from "react-router-dom";
function Sanctions() {
    return (
        <>

            <div className="system-administration py-5">
                <div className="container-fluid">
                    <div className="row justify-content-start">
                        <div className="col-12">
                            <h3>Sanctions</h3>
                        </div>
                        <div className="col mb-4 system-administration-box">
                            <div className="">
                                <a href="/">
                                    <img src="../icons/geo.png" className="icons" alt="icons" />
                                    <h5>Geo</h5>
                                </a>
                            </div>
                        </div>
                        <div className="col mb-4 system-administration-box">
                            <div className="">
                                <a href="/">
                                    <img src="../icons/entity.png" className="icons" alt="icons" />
                                    <h5>Entity</h5>
                                </a>
                            </div>
                        </div>
                        <div className="col mb-4 system-administration-box">
                            <div className="">
                                <a href="/">
                                    <img src="../icons/people.png" className="icons" alt="icons" />
                                    <h5>People</h5>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sanctions;
