import React from "react";
import { useSelector } from "react-redux";
import Failure from "./Failure";
import Success from "./Success";
function Result() {
  const select = useSelector((state) => state);
  console.log("🚀 ~ file: Result.js ~ line 7 ~ Result ~ select", select)

  // const temp = () => {
  //   console.log(select.resultReducer, "result");
  // };
  // useEffect(() => {
  //   temp();
  //   // eslint-disable-next-line
  // }, [select]);

  console.log("hello", select)
  return (
    <>
      {/* <Header /> */}
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
            {/* <Sidebar /> */}
          </div>
          {select.EncryptDataReducer.data.status === 200 && (
            <Success resultDatas={select} />
          )}
          {(select.EncryptDataReducer.data.status !== 200) && (
            <Failure resultDatas={select} />
          )}
        </div>
      </div>
    </>
  );
}

export default Result;
