import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showLoader } from "../../../../src/action";
import { getDropdownvalues } from "../../../API/dropdown";
import Pagination from "../../../Components/NewPagination";
import { FetchBanks, FetchBanksChild } from "../../../API/MoneyServices";

// import { decrypytdata } from "../../../../api/walletKey";
function Originator({ setTargetData, nextstep,userData }) {
  const publicKeys = useSelector((state) => state.keyReducer);

  const [privateModal, setPrivateModal] = useState(false);
  const [bankSearch, setBankSearch] = useState("");
  // const [bankClassdropdown, setBankClassdropdown] = useState([]);
  const [bankTypedropdown, setBankTypedropdown] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  // const [country, setCountry] = useState([]);
  const [maxRows, setMaxRows] = useState("");
  const [rows, setRows] = React.useState([]);
  // const [bankclassData, setBankClassItem] = useState("");
  const [bankTypeData, setBankTypeData] = useState("");
  // const [bankData, setBankData] = useState();

  // const [centralBanksData, setCentralBanksData] = useState("");
  // const [branchData, setBranchData] = useState("");
  // const [memberBanksData, setMemberBanksData] = useState("");
  // const [CountryCodeData, setCountryCodeData] = useState([]);
  // const [bankTreeDatas, setbankTreeDatas] = useState([]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Checked row
  // const [inchecked, setInChecked] = useState(false);
  
  //paginason
  const [currentPage, setCurrentPage] = useState(1);
  const [postparPage, setPostparPage] = useState(25);
  const dispatch = useDispatch();

  // const indexofLastpage = currentPage * postparPage;
  // const indexofFirstpost = indexofLastpage - postparPage;

  const fetchdropdwon = async () => {
    // let LKBankClass = await getDropdownvalues("LKBankClass");
    // setBankClassdropdown(LKBankClass);
    let LKBankType = await getDropdownvalues("LKInstitutionType");
    setBankTypedropdown(LKBankType);
    // let LKBankCoutnryType = await getDropdownvalues("LKCentralBanks");
    // setCountry(LKBankCoutnryType);
  };

  //   const datass = {
  //     bankclasscode: "CB",
  //     bankTypecode: "01",
  //     countrycode: "IN",
  //     statecode: "13006",
  //     citycode: "121082",
  //     parentBankID: 213565,
  //   };
  let APPID = "";
  let APIKEY = "";
  // const closeModal = () => {
  //   setPrivateModal(false);
  // };

  const fetchdata = async () => {
    if (userData.PublicAddress) {
      APPID = userData.PublicAddress;
    }
    if (userData.PrivateKey) {
      APIKEY = userData.PrivateKey;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));

      let values;
      if (bankTypeData) {
        values = {
          sourcePublicAddress: APPID,
          bankTypecode: bankTypeData,
          itemcount: postparPage,
          pageId: currentPage,
          // searchPhrase: bankSearch,
          // bankclasscode: bankclassData,
          // parentBankID: centralBanksData,
          // countrycode: "",
          // statecode: memberBanksData,
          // citycode: branchData,
        };
      } else if (bankSearch) {
        values = {
          sourcePublicAddress: APPID,
          itemcount: postparPage,
          pageId: currentPage,
          searchPhrase: bankSearch,
          // parentBankID:"",
        };
      } else {
        values = {
          sourcePublicAddress: APPID,
          itemcount: postparPage,
          pageId: currentPage,
          // parentBankID:"",
        };
      }
      const encryptedLedger = await FetchBanks(APPID, APIKEY, values);
      // const tokens = await decrypytdata(encryptedLedger.data, APIKEY);
      // const data = JSON.parse(JSON.parse(tokens).CommandResponse);
      const data = encryptedLedger?.data;
      // setbankTreeDatas(encryptedLedger?.data?.bankTree);
      // let data = JSON.parse(JSON.parse(tokens).CommandResponse);

      if (data) {
        let dataArr = [];
        let temp = data.shift();

        if (temp) {
          setMaxRows(temp.maxRows);
        }
        data?.forEach((dataMain, index) => {
          // multiple data
          dataArr.push({ ...dataMain, rowid: index });
        });
        setRows(dataArr);
      }
    }
    dispatch(showLoader(false));
  };

  const fetchdataChildHandler = async (ndata) => {
    console.log(ndata, "ndata");
    console.log("hellooo");
    if (userData.PublicAddress) {
      APPID = userData.PublicAddress;
    }
    if (userData.PrivateKey) {
      APIKEY = userData.PrivateKey;
    }
    if (APPID && APIKEY) {
      const values = {
        parentBankID: ndata,
      };
      // if (bankclassData && bankTypeData) {
      //   values = {
      //     sourcePublicAddress: APPID,
      //     bankclasscode: bankclassData,
      //     bankTypecode: bankTypeData,
      //   };
      // } else {
      //   values = {
      //     sourcePublicAddress: APPID,
      //     // parentBankID:"",
      //   };
      // }

      const encryptedLedger = await FetchBanksChild(APPID, APIKEY, values);
      // const tokens = await decrypytdata(encryptedLedger.data, APIKEY);
      // const data = JSON.parse(JSON.parse(tokens).CommandResponse);
      const data = encryptedLedger?.data;
      // setbankTreeDatas(encryptedLedger?.data?.bankTree);
      // let data = JSON.parse(JSON.parse(tokens).CommandResponse);

      if (data) {
        let dataArr = [];
        data?.forEach((dataMain, index) => {
          // multiple data
          dataArr.push({ ...dataMain, rowid: index });
        });
        setRows(dataArr);
      }
    }
  };

  useEffect(() => {
    fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankTypeData, postparPage, currentPage, bankSearch]);

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (!publicKeys?.privateKey?.PrivateWalletAddress) setPrivateModal(true);
    }
    fetchdata();
    fetchdropdwon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys, privateModal]);

  useEffect(() => {
    setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postparPage]);

  return (
    <>
      <div className="row mt-5">
        <div className="col-xxl-11">
          <div className="wallet-balances-box">
            <div className="d-md-flex justify-content-end px-4">
              <div className="">
                <div className="line-input input-group mb-3">
                  <span className="input-group-text">
                    <i className="bi bi-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="search"
                    onChange={(e) => {
                      setBankSearch(e.target.value);
                      console.log("abdkjbadb", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className=" ">
              <Paper
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  border: 1,
                  borderColor: "#c6c6c6",
                }}
              >
                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: "none",
                    maxHeight: 500,
                    borderBottom: 1,
                    borderColor: "#c6c6c6",
                  }}
                >
                  <Table
                    stickyHeader
                    className="table table-hover table-striped"
                    sx={{ minWidth: 650, mb: 0 }}
                    aria-label="simple table"
                  >
                    <TableHead
                      className="sticky-top"
                      sx={{
                        zIndex: "10",
                        borderBottom: 1,
                        borderColor: "#c6c6c6",
                        backgroundColor:"#fff", 

                      }}
                    >
                      <TableRow className="data-table-row">
                        <TableCell className="data-table-head">
                          <div className="lable-div">
                            <span className="text-dark">Select</span>
                          </div>
                        </TableCell>

                        <TableCell className="data-table-head">
                          <div className="lable-div">
                            <span className="text-dark">Bank </span>
                          </div>
                        </TableCell>
                        <TableCell
                          className="data-table-head"
                          sx={{ minWidth: 250 }}
                        >
                          <div className="lable-div">
                            <span className="text-dark">
                              Digital Currency Account
                            </span>
                          </div>
                        </TableCell>
                        <TableCell
                          className="data-table-head"
                          sx={{ minWidth: 150 }}
                        >
                          <div className="lable-div">
                            <span className="text-dark">Bank Class</span>
                          </div>
                        </TableCell>

                        {/* <TableCell
                          className="Bank Class"
                          sx={{ minWidth: 150 }}
                        >
                          <span className="text-dark">Bank Class</span> */}
                        {/* <select
                            class="form-select"
                            aria-label="Default select example"
                            // value={e.target.value}
                            onChange={(e) => setBankClassItem(e.target.value)}
                          > */}
                        {/* <option selected> Bank Class</option> */}

                        {/* {bankClassdropdown.map((index) => {
                              return (
                                <>
                                  <option value={index.keycode}>
                                    {index.displayName}
                                  </option>
                                </>
                              );
                            })} */}
                        {/* </select> */}

                        {/* </TableCell> */}
                        <TableCell sx={{ minWidth: 150 }}>
                          <span className="text-dark"> Bank Type</span>

                          <select
                            class="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setBankTypeData(e.target.value)}
                            placeholder="Bank Type"
                          >
                            <option selected>Bank Type</option>
                            {bankTypedropdown.commandDataset?.map((index) => {
                              return (
                                <>
                                  <option value={index.Keycode}>
                                    {index.Displayname}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </TableCell>

                        <TableCell className="data-table-head">
                          <div className="lable-div">
                            <span className="text-dark">Country</span>
                          </div>
                        </TableCell>

                        <TableCell className="data-table-head">
                          <div className="lable-div">
                            <span className="text-dark">State Province</span>
                          </div>
                        </TableCell>

                        <TableCell className="data-table-head">
                          <div className="lable-div">
                            <span className="text-dark">City Town</span>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* {rows?.length > 0 &&
                    rows
                      ?.slice(indexofFirstpost, indexofLastpage)
                      ?.filter((user) => {
                        if (
                          tokenClassData === "" &&
                          bankclassItem === "" &&
                          tokenTypeData === ""
                        ) {
                          return user;
                        } else if (
                          user.tokenClass
                            .toLowerCase()
                            .includes(tokenClassData.toLowerCase()) &&
                          user.currencycodeList
                            .toLowerCase()
                            .includes(bankclassItem.toLowerCase()) &&
                          user.tokenType
                            .toLowerCase()
                            .includes(tokenTypeData.toLowerCase())
                        ) {
                          return user;
                        }
                      }) */}
                      {rows?.map((items, index) => {
                        return (
                          <>
                            <TableRow
                              className={" " + (checkedRows ? "rowselect" : "")}
                              onDoubleClick={() => {
                                console.log("double");
                                setTargetData(items);
                                nextstep();
                              }}
                              // key={index}
                              // onClick={() => setActive(items)}
                              // id={index}
                              // className={isActive === items ? "is-checked" : ""}
                            >
                              {/* <span>{index}</span> */}
                              <TableCell sx={{position:"relative"}}>
                                <div className=" table-form-check ">
                                  <input
                                    className="form-check-input ml-0"
                                    type="checkbox"
                                    id="flexCheckDefault"
                                    name="flexCheckDefault"
                                    checked={checkedRows === items.RowID}
                                    onChange={() => {
                                      setCheckedRows(items.RowID);
                                      // setInChecked(true);
                                    }}
                                    onClick={() => {
                                      setTargetData(items);
                                    }}
                                  />
                                </div>
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  fetchdataChildHandler(items.BankID);
                                }}
                              >
                                {items.BanknameShort}
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  fetchdataChildHandler(items.BankID);
                                }}
                              >
                                {items.Displayname}
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  fetchdataChildHandler(items.BankID);
                                }}
                              >
                                {items.Bankclass}
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  fetchdataChildHandler(items.BankID);
                                }}
                              >
                                {items.BankType}
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  fetchdataChildHandler(items.BankID);
                                }}
                              >
                                {items.Countrycode}
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  fetchdataChildHandler(items.BankID);
                                }}
                              >
                                {items.StateProvince}
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  fetchdataChildHandler(items.BankID);
                                }}
                              >
                                {items.CityTown}
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box sx={{ width: "100%" }}>
                  <Box className="row py-3 px-2">
                    <Box className="col-12 col-md-3 col-xl-2">
                      <div className="row-select">
                        <select
                          class="form-select pagination-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            setPostparPage(e.target.value);
                          }}
                          // placeholder="Bank Type"
                        >
                          {/* <option selected>Select rows</option> */}

                          <option value={10}>10 Rows</option>
                          <option selected value={25}>
                            25 Rows
                          </option>
                          <option value={50}>50 Rows</option>
                          <option value={100}>100 Rows</option>
                          <option value={200}>200 Rows</option>
                        </select>
                      </div>
                    </Box>

                    <Box className="col-12 col-xl-10 col-md-9 d-flex justify-content-end align-items-center">
                      <Pagination
                        postsperPage={postparPage}
                        maxRows={maxRows}
                        paginate={paginate}
                        currentPage={currentPage}
                      />
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Originator;
