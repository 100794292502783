import Sidebar from "../../Components/Sidebar/Sidebar";
import Footer from "../../Components/Footer.js/Footer";
import Header from "../../Components/Navbar/Header";

function AddGroup() {
    return (
        <>
            <Header />
            <div className="container-fluid">
                <div className="create-group-page">
                    <div className="row">
                        <div className="col-md-4 col-lg-3 col-xl-auto p-0 sidebar">
                            <Sidebar />
                        </div>
                        <div className="col-md-8 col-lg-9 col-xl ps-xl-0">
                            <div className="create-group ps-md-3 ps-lg-5">
                                <div className="main-head">
                                    <i className="bi bi-arrow-left"></i>
                                    <h2>Create Group</h2>
                                </div>
                                <div className="create-group-box">
                                    <h4>Group Information</h4>
                                    <p>Manage all the user registrations within your enterprise.</p>
                                    <form className="create-group-form">
                                        <div className="row mb-xl-5">
                                            <div className="col-sm-6 col-xl-3 mb-5">
                                                <h6>Code Information</h6>
                                                <div className="mb-4 mb-md-5">
                                                    <label>Parent Group Code</label>
                                                    <div className="input-box">
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            placeholder="Parent Group Code"
                                                        />
                                                        <i className="bi bi-check-lg"></i>
                                                    </div>
                                                </div>
                                                <div className="mb-4 mb-md-5">
                                                    <label>Group Code</label>
                                                    <div className="input-box">
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            placeholder="Group Code"
                                                        />
                                                        <i className="bi bi-check-lg"></i>
                                                    </div>
                                                </div>
                                                <div className="mb-4 mb-md-5">
                                                    <label>Native Identifier</label>
                                                    <div className="input-box">
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            placeholder="Native Identifier"
                                                        />
                                                        <i className="bi bi-check-lg"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-xl-3 mb-5">
                                                <h6>Name</h6>
                                                <div className="mb-4 mb-md-5">
                                                    <label>Group Name</label>
                                                    <div className="input-box">
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            placeholder="Group Name"
                                                        />
                                                        <i className="bi bi-check-lg"></i>
                                                    </div>
                                                </div>
                                                <div className="mb-4 mb-md-5">
                                                    <label>Alias Name</label>
                                                    <div className="input-box">
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            placeholder="Alias Name"
                                                        />
                                                        <i className="bi bi-check-lg"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-xl-3 mb-5">
                                                <h6>Group Class and Type</h6>
                                                <div className="mb-4 mb-md-5">
                                                    <label>Group Class</label>
                                                    <select className="form-select form-control">
                                                        <option defaultValue>--Select--</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                                <div className="mb-4 mb-md-5">
                                                    <label>Group Type</label>
                                                    <select className="form-select form-control">
                                                        <option defaultValue>--Select--</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-xl-3 mb-5">
                                                <h6>Address </h6>
                                                <div className="mb-4 mb-md-5">
                                                    <label>Country</label>
                                                    <div className="input-box">
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            placeholder="Country"
                                                        />
                                                        <i className="bi bi-check-lg"></i>
                                                    </div>
                                                </div>
                                                <div className="mb-4 mb-md-5">
                                                    <label>State Province</label>
                                                    <div className="input-box">
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            placeholder="State Province"
                                                        />
                                                        <i className="bi bi-check-lg"></i>
                                                    </div>
                                                </div>
                                                <div className="mb-4 mb-md-5">
                                                    <label>City Town</label>
                                                    <div className="input-box">
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            placeholder="City Town"
                                                        />
                                                        <i className="bi bi-check-lg"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-xl-3 mb-5">
                                                <h6>Detail Address</h6>
                                                <div className="mb-4 mb-md-5">
                                                    <label>AddressLine1</label>
                                                    <div className="input-box">
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            placeholder="AddressLine1"
                                                        />
                                                        <i className="bi bi-check-lg"></i>
                                                    </div>
                                                </div>
                                                <div className="mb-4 mb-md-5">
                                                    <label>AddressLine2 </label>
                                                    <div className="input-box">
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            placeholder="AddressLine2 "
                                                        />
                                                        <i className="bi bi-check-lg"></i>
                                                    </div>
                                                </div>
                                                <div className="mb-4 mb-md-5">
                                                    <label>Postalcode </label>
                                                    <div className="input-box">
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            placeholder="Postalcode "
                                                        />
                                                        <i className="bi bi-check-lg"></i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-xl-3 mb-5">
                                                <h6>Contact Information</h6>
                                                <div className="mb-4 mb-md-5">
                                                    <label>First Name </label>
                                                    <div className="input-box">
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            placeholder="First Name "
                                                        />
                                                        <i className="bi bi-check-lg"></i>
                                                    </div>
                                                </div>
                                                <div className="mb-4 mb-md-5">
                                                    <label>Middle Name </label>
                                                    <div className="input-box">
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            placeholder="Middle Name "
                                                        />
                                                        <i className="bi bi-check-lg"></i>
                                                    </div>
                                                </div>
                                                <div className="mb-4 mb-md-5">
                                                    <label>Last Name </label>
                                                    <div className="input-box">
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            placeholder="Last Name "
                                                        />
                                                        <i className="bi bi-check-lg"></i>
                                                    </div>
                                                </div>
                                                <div className="mb-4 mb-md-5">
                                                    <label>Second Last Name </label>
                                                    <div className="input-box">
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            placeholder="Second Last Name "
                                                        />
                                                        <i className="bi bi-check-lg"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-xl-3 mb-5">
                                                <h6>Contact</h6>
                                                <div className="mb-4 mb-md-5">
                                                    <label>Email Address </label>
                                                    <div className="input-box">
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            placeholder="Email Address "
                                                        />
                                                        <i className="bi bi-check-lg"></i>
                                                    </div>
                                                </div>
                                                <div className="mb-4 mb-md-5">
                                                    <label>Dialing Countrycode </label>
                                                    <div className="input-box">
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            placeholder="Dialing Countrycode "
                                                        />
                                                        <i className="bi bi-check-lg"></i>
                                                    </div>
                                                </div>
                                                <div className="mb-4 mb-md-5">
                                                    <label>Mobile Phone Number </label>
                                                    <div className="input-box">
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            placeholder="Number "
                                                        />
                                                        <i className="bi bi-check-lg"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5 mb-5">
                                            <button type="button" className="btn cancel-btn mb-3 mb-md-0 me-4" >CANCEL</button>
                                            <button type="button" className="btn next-btn mb-3 mb-md-0">NEXT</button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 p-0">
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddGroup;