import React from "react";

export default function Pagination({
  maxRows,
  postsperPage,
  paginate,
  currentPage,
}) {
  // for (let i = 1; i <= Math.ceil(totalPost / postsperPage); i++) {
  //   pageNumber.push(i);
  //   console.log(pageNumber, "pageNumber");
  // }

  return (
    <div className="d-flex justify-content-end ">
      <div className="row mb-8 d-flex">
        <div className="col-sm-12 col-md-12 d-flex align-items-end justify-content-end justify-content-md-end d-flex w-100">
          <div
            className="dataTables_paginate paging_simple_numbers"
            id="kt_profile_overview_table_paginate"
          >
            <ul className="pagination mb-0 custom-pagination">
              <li
                className="paginate_button page-item previous disabled"
                id="kt_profile_overview_table_previous"
              >
                {/* <a
                  href="#"
                  aria-controls="kt_profile_overview_table"
                  data-dt-idx="0"
                  className="page-link"
                >
                    {'<'}
                </a> */}
              </li>
              <div className="pagination-table align-items-center  d-flex">
                <img
                  src="/icons/firstpage.svg"
                  alt="firstpage"
                  onClick={() => {
                    paginate(1);
                  }}
                  className="pointer"
                />
                <img
                  src="/icons/rightArrow.svg"
                  alt="Previouspage"
                  className="roteted180 ml-1 pointer"
                  onClick={() => {
                    if (currentPage > 1) {
                      paginate(currentPage - 1);
                    } else {
                      paginate(1);
                    }
                  }}
                />
                <div className="mx-2">
                  <input
                    // type="number"
                    onChange={(e) => {
                      console.log(e.target.value);
                      paginate(e.target.value);
                      //   if(e.target.value){
                      //   }else{
                      //     paginate(1)
                      //   }
                    }}
                    className="border-bottom"
                    value={currentPage}
                  />
                  {maxRows && (
                    <span> of {Math.round(maxRows / postsperPage)}</span>
                  )}
                </div>
                <img
                  src="/icons/rightArrow.svg"
                  alt="Nextpage"
                  className="ml-1 pointer"
                  onClick={() => {
                    let lastpage = Math.round(maxRows / postsperPage);

                    if (lastpage > currentPage) {
                      paginate(currentPage + 1);
                    }
                  }}
                />
                <img
                  src="/icons/firstpage.svg"
                  alt="Lastpage"
                  className="roteted180 pointer"
                  onClick={() => {
                    paginate(() => {
                      if (Math.round(maxRows / postsperPage) % 1 === 0) {
                        return Math.round(maxRows / postsperPage);
                      } else {
                        return Math.round(maxRows / postsperPage) + 1;
                      }
                    });
                  }}
                />
              </div>
              {/* {pageNumber.map((number) => (
                <li
                  className={`paginate_button page-item cursor-pointer ${
                    currentPage === number && "active"
                  }`}
                ></li>
              ))} */}
              <li
                className="paginate_button page-item next"
                id="kt_profile_overview_table_next"
              >
                {/* <a
                  href="#"
                  aria-controls="kt_profile_overview_table"
                  data-dt-idx="4"
                  className="page-link"
                >
                  {'>'}
                </a> */}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
