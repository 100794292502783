import React from "react";
import { Link } from "react-router-dom";
//import { Link } from "react-router-dom";
function SystemAdministration() {
    return (
        <>
            <div className="system-administration py-5">
                <div className="container-fluid">
                    <div className="row justify-content-start">
                        <div className="col-12">
                            <h3>System Administration</h3>
                        </div>
                        <div className="col mb-4 system-administration-box">
                            <div className="">
                                <a href="/">
                                    <img src="../icons/seed-file.png" className="icons" alt="icons" />
                                    <h5>Seed File</h5>
                                </a>
                            </div>
                        </div>
                        <div className="col mb-4 system-administration-box">
                            <div className="">
                                <a href="/">
                                    <img src="../icons/profile.png" className="icons" alt="icons" />
                                    <h5>Profile</h5>
                                </a>
                            </div>
                        </div>
                        <div className="col mb-4 system-administration-box">
                            <div className="">
                                <a href="/">
                                    <img src="../icons/location.png" className="icons" alt="icons" />
                                    <h5>Locations</h5>
                                </a>
                            </div>
                        </div>
                        <div className="col mb-4 system-administration-box">
                            <div className="">
                                <a href="/">
                                    <img src="../icons/users.png" className="icons" alt="icons" />
                                    <h5>Users</h5>
                                </a>
                            </div>
                        </div>
                        <div className="col mb-4 system-administration-box">
                            <div className="">
                                <a href="/">
                                    <img src="../icons/setting.png" className="icons" alt="icons" />
                                    <h5>Settings</h5>
                                </a>
                            </div>
                        </div>
                        <div className="col mb-4 system-administration-box">
                            <div className="">
                                <a href="/">
                                    <img src="../icons/ip-addresses.png" className="icons" alt="icons" />
                                    <h5>IP Addresses</h5>
                                </a>
                            </div>
                        </div>
                        <div className="col mb-4 system-administration-box">
                        <Link to="/admin/data-dictionary">

                            <div className="">
                                <a href="/">
                                    <img src="../icons/data-dictionary.png" className="icons" alt="icons" />
                                    <h5>Data Dictionary</h5>
                                </a>
                            </div>
                        </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SystemAdministration;
