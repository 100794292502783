import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/Logo.png";
import { FaEnvelope, FaBars } from "react-icons/fa";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <header>
      <nav id="header" className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <Link to="/" className="navbar-brand" href="/">
            <img src={logo} alt="DCMA's Logo" className="img-logo img-fluid" />
          </Link>
          <button
            type="button"
            className="navbar-toggler"
            onClick={() => setIsOpen(!isOpen)}
          >
            <FaBars />
          </button>
          <div
            id="navbarNav"
            className={`${!isOpen ? "collapse" : ""} navbar-collapse`}
          >
            <div className="top-bar">
              <p>Digital Currency Monetary Authority</p>
              <Link to="/">
                <FaEnvelope />
              </Link>
            </div>
            <ul className="navbar-nav d-flex align-items-center">
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  ABOUT US
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" routerlink="/kyc" className="nav-link active">
                  KYC
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  GDPC
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  DIGITAL BANKING
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/"
                  routerlink="/digital-trade"
                  className="nav-link"
                >
                  DIGITAL TRADE
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  CBDA
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  MEMBERSHIP
                </Link>
              </li>
              <li className="nav-item" style={{ paddingLeft: "20px" }}>
                <Link
                  to="/"
                  className="nav-link"
                  style={{ color: "black !important" }}
                >
                  REGISTER
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/"
                  className="nav-link"
                  style={{ color: "black !important" }}
                >
                  Login
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};
export default Header;
