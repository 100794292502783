import dcma from "../../assets/images/dcma.png";

const Footer = () => {
  return (
    <footer>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4 col-xl-2 d-flex justify-content-center">
            <a href="/">
              <img
                src={dcma}
                alt="DCMA's Logo"
                className="img-logo img-fluid"
              />
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-2">
            <h6 className="text-uppercase">ABOUT US</h6>
            <p>
              <a href="/about-us" routerlink="/about-us">
                Our Mission{" "}
              </a>
            </p>
            <p>
              <a href="#/about-us" routerlink="/about-us">
                Executive Team
              </a>
            </p>
            <p>
              <a href="#/about-us" routerlink="/about-us">
                Careers
              </a>
            </p>
            <p>
              <a href="#/about-us" routerlink="/about-us">
                News Events
              </a>
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-2">
            <h6 className="text-uppercase">SOLUTIONS</h6>
            <p>
              <a href="#/digital-banking" routerlink="/digital-banking">
                Digital Banking
              </a>
            </p>
            <p>
              <a href="#/digital-trade" routerlink="/digital-trade">
                Digital Currency
              </a>
            </p>
            <p>
              <a href="#/digital-trade" routerlink="/digital-trade">
                Digital Trade
              </a>
            </p>
            <p>
              <a href="#/bis-swift" routerlink="/bis-swift">
                Digital Payments
              </a>
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-2">
            <h6 className="text-uppercase">MEMBERS</h6>
            <p>
              <a href="#/membership" routerlink="/membership">
                Central Banks
              </a>
            </p>
            <p>
              <a href="#/membership" routerlink="/membership">
                Correspondent Banks
              </a>
            </p>
            <p>
              <a href="#/membership" routerlink="/membership">
                Global Trade Banks
              </a>
            </p>
            <p>
              <a href="#/membership" routerlink="/membership">
                Commercial Banks
              </a>
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-2">
            <h6 className="text-uppercase">CONTACT US</h6>
            <p>
              <a href="#/membership" routerlink="/membership">
                Membership
              </a>
            </p>
            <p>
              <a href="#/nodes" routerlink="/nodes">
                Node Participation
              </a>
            </p>
            <p>
              <a href="#/contact-us" routerlink="/contact-us">
                Technical Support
              </a>
            </p>
            <p>
              <a href="#/contact-us" routerlink="/contact-us">
                General Inquiry
              </a>
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-2">
            <h6 className="text-uppercase">HEADQUARTERS</h6>
            <p className="footer-address">
              {" "}
              United States Switzerland Singapore{" "}
            </p>
          </div>
        </div>
        <div className="footer-copyright">
          <div>
            <a href="/"> Copyright © 2021 Digital Currency Monetary Authority.</a>
          </div>
          <div>
            <a href="#/term-conditions" routerlink="/term-conditions">
              {" "}
              Terms and Conditions &nbsp;
            </a>
            <a href="#/privacy" routerlink="/privacy">
              | &nbsp; Privacy Policy{" "}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
