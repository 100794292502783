import React from "react";
import Header from "./Header";
import SystemAdministration from "./SystemAdministration";
import MasterDataManagement from "./MasterDataManagement";
import CentralBankDigitalCurrencies from "./CentralBankDigitalCurrencies";
import PaymentSystems from "./PaymentSystems";
import Sanctions from "./Sanctions";
import ReportingandAnalytics from "./ReportingandAnalytics";
import MoneyServices from "./MoneyServices";
import Footer from "./Footer";

const CryptoHome = () => {
    return (
        <div className="root">
            <Header />
            <SystemAdministration />
            <hr />
            <CentralBankDigitalCurrencies />
            <hr />
            <MasterDataManagement />
            <hr />
            <PaymentSystems />
            <hr />
            <Sanctions />
            <hr />
            <ReportingandAnalytics />
            <hr />
            <MoneyServices />
            <Footer />
        </div>
    );
};

export default CryptoHome;
