/* eslint-disable no-unused-vars */
import CryptoJS from "crypto-js";
import axios from "axios";
import { ADMIN_URL, encryptionString } from "../Constants/Config";
function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    // eslint-disable-next-line no-mixed-operators
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}
export async function hmacencrypt(
  method,
  uri,
  postObject,
  publickey,
  privatekey
) {
  var jsonPayload = JSON.stringify(postObject);
  var requestUri = encodeURIComponent(uri);
  var requestHttpMethod = method;
  var requestTimeStamp = (
    Math.floor(new Date().getTime() / 1000) -
    Math.floor(new Date("01-Jan-1970 00:00:00 AM UTC").getTime() / 1000)
  ).toString();
  var nonce = uuidv4().replace(/-/g, "");
  var requestContentBase64String = await CryptoJS.enc.Base64.stringify(
    CryptoJS.MD5(jsonPayload)
  );
  // var bytesPublicAddress = await CryptoJS.AES.decrypt(
  //   localStorage.getItem("publicAddress"),
  //   encryptionString
  // );
  // var bytesPublicAddress = await CryptoJS.AES.decrypt(
  //   publickey,
  //   encryptionString
  // );
  var APPId = publickey;
  // var APPId = await JSON.parse(bytesPublicAddress.toString(CryptoJS.enc.Utf8));
  // var bytesPrivateKey = await CryptoJS.AES.decrypt(
  //   localStorage.getItem("privateKey"),
  //   encryptionString
  // );
  // var bytesPrivateKey = await CryptoJS.AES.decrypt(
  //   privatekey,
  //   encryptionString
  // );
  // var APIKey = JSON.parse(bytesPrivateKey.toString(CryptoJS.enc.Utf8));
  var APIKey = privatekey;
  // var APPId = "6a0d198d-f36a-4119-8f92-5b0eb31d9ad7";
  // var APIKey = "6f/9BSoCl3kit4bhQAmj0g4/lMu6rt54M57UILrei1o=";
  // var signatureRawData = APPId[0].publicKey.publicAddress.concat(
  //   requestHttpMethod,
  //   requestUri,
  //   requestTimeStamp,
  //   nonce,
  //   requestContentBase64String
  // );
  var signatureRawData = APPId.concat(
    requestHttpMethod,
    requestUri,
    requestTimeStamp,
    nonce,
    requestContentBase64String
  );
  // var hash = CryptoJS.HmacSHA256(
  //   signatureRawData,
  //   APIKey[0].privateKey.PrivateWalletAddress
  // );
  var hash = CryptoJS.HmacSHA256(signatureRawData, APIKey);
  // var hash = CryptoJS.HmacSHA256(signatureRawData, APIKey);
  var hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
  // return (
  //   "Hmac " +
  //   APPId[0].publicKey.publicAddress +
  //   ":" +
  //   hashInBase64 +
  //   ":" +
  //   nonce +
  //   ":" +
  //   requestTimeStamp +
  //   ":" +
  //   requestUri
  // );
  return (
    "Hmac " +
    APPId +
    ":" +
    hashInBase64 +
    ":" +
    nonce +
    ":" +
    requestTimeStamp +
    ":" +
    requestUri
  );
}
export let validateApiResponse;
export async function validatePublicKey(publickey) {
  const res = await axios.post(
    ADMIN_URL + "/security/ValidatePublicWallet?publicAddress=" + publickey
  );
  validateApiResponse = res
  if (res.data.isValidWallet === true) {
    return res.data;
  } else {
    return "";
  }
}

export async function decrypytdata(data, APIKEY) {
  var ciphertextB64 = data; //"NsFJlGQScUEazmSEykVeO/lh+o2L5ykFd2hkNa5lVrHACwKfTg1pD/uYzjTfjmQO";
  var keys = APIKEY;
  var splitkey = keys.split("", 32);
  var stringkey = splitkey.join("");
  var key = CryptoJS.enc.Utf8.parse(stringkey); // Convert into WordArray (using Utf8)
  var iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]); // Use zero vector as IV
  var decrypted = CryptoJS.AES.decrypt(ciphertextB64, key, { iv: iv }); // By default: CBC, PKCS7
  var plaintext = decrypted.toString(CryptoJS.enc.Utf8);
  return plaintext;
}
export async function decrypytlocalStoragedata(data) {
  var bytes = await CryptoJS.AES.decrypt(data, encryptionString);
  let plaintext = await JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return plaintext;
}
