import Register from "./Views/Register/Register";
import Login from './Views/Login/Login'
import AddGroup from "./Views/AddGroup/AddGroup";
import GetSSP from "./Pages/SSP/GetSSP"
import DataDictionary from "./Pages/DataDictionary/DataDictionary"
import ClearingSystemType from "./Views/admin/SysAdmin/pages/ClearingType";
import RoutingSystems from "./Views/admin/SysAdmin/pages/RoutingSystems";
import RoutingSystemType from "./Views/admin/SysAdmin/pages/RoutingSystemType";
import SwiftCodes from "./Views/admin/SysAdmin/pages/SwiftCodes";
import ClearingSystem from "./Views/admin/SysAdmin/pages/ClearingSystems";
import Home from "../src/Views/admin/SysAdmin/index";
import BankHome from "./Views/admin/BANK-SA";
import CryptoHome from "./Views/admin/CRYPTO-SA";
import IssuerHome from "./Views/admin/ISSUER-SA";




const routes = [
  {
    name: "Register",
    element: Register,
    path: "/register",
  },
  {
    name: "Login",
    element: Login,
    path: "/",
  },
  {
    name: "AddGroup",
    element: AddGroup,
    path: "/addgroup",
  },
  {
    name: "PORTAL-SA",
    element: Home,
    path: "/system-administrator",
  },
  {
    name: "BANK-SA",
    element: BankHome,
    path: "/bank-administrator",
  },
  {
    name: "ISSUER-SA",
    element: IssuerHome,
    path: "/issuer-administrator",
  },
  {
    name: "CRYPTO-SA",
    element: CryptoHome,
    path: "/crypto-administrator",
  },
  {
    name: "GetSSP", 
    element: GetSSP,
    path: "/admin/settlement-networks",
  },
  {
    name: "DataDictionary", 
    element: DataDictionary,
    path: "/admin/data-dictionary", 
  },
  {
    name: "ClearingSystemType", 
    element: ClearingSystemType,
    path: "/admin/clearing-system-type", 
  },
  {
    name: "RoutingSystems", 
    element: RoutingSystems,
    path: "/admin/routing-systems", 
  },
  {
    name: "RoutingSystemType", 
    element: RoutingSystemType,
    path: "/admin/routing-system-type", 
  },
  {
    name: "SwiftCodes", 
    element: SwiftCodes,
    path: "/admin/swift-codes", 
  },
  {
    name: "ClearingSystem", 
    element: ClearingSystem,
    path: "/admin/clearing-system", 
  },
  
];
export default routes;
